import {
  ButtonCol,
  ContentContainer,
  InnerPageContainer,
  PageContainer,
  SearchContainer,
  TableWrapper,
  TitleCol,
  UpperRow,
} from "./styled";
import { Title } from "../../../../library/title";
import Button from "../../../../library/button";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../types";
import Input from "../../../../library/input";
import { useTranslation } from "react-i18next";
import Tabs from "../../../../library/tabs";
import TableLoader from "../../../../library/table-loader";
import EmptyView from "../../../../components/empty-view";
import Table from "./table";
import useFetchAdminBlogposts from "../../../../hooks/useFetchAdminBlogposts";
import { useEffect } from "react";
import {
  clearDeletionArray,
  setSearchQuery,
} from "../../../../redux/actions/blogposts";
import useDeleteBlogposts from "../../../../hooks/useDeleteBlogposts";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../library/notification";
import useModal from "../../../../hooks/useModal";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, fetchBlogpostsList } = useFetchAdminBlogposts();
  const { deleteBlogposts } = useDeleteBlogposts();
  const { Confirm: DeleteModal, closeModal: closeDeleteModal } = useModal();
  const user = useSelector((state: IState) => state.user.details);
  const deletions = useSelector(
    (state: IState) => state.transcripts.deletionArray
  );

  const searchQuery = useSelector(
    (state: IState) => state.transcripts.searchQuery
  );

  const publishedBlogposts = useSelector(
    (state: IState) => state.blogposts?.list?.published
  );
  const draftBlogposts = useSelector(
    (state: IState) => state.blogposts?.list?.draft
  );

  const handleSearch = (e: any) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const blogposts = (type: "published" | "draft") => {
    if (searchQuery) {
      if (type === "published") {
        return publishedBlogposts.filter((x) => x.title.includes(searchQuery));
      }
      return draftBlogposts.filter((x) => x.title.includes(searchQuery));
    }
    if (type === "draft") {
      return draftBlogposts;
    }
    return publishedBlogposts;
  };

  const renderList = (type: "published" | "draft") => {
    if (loading) {
      return <TableLoader message="Henter transkripter.." />;
    }
    if (!loading && blogposts(type)?.length === 0) {
      return (
        <EmptyView
          title="Ingen blogginnlegg"
          description="Det ser ikke ut som du har opprettet noen blogginnlegg enda. Trykk på 'Nytt innlegg'-knappen øverst i høyre hjørne for å starte!"
        />
      );
    }
    return <Table data={blogposts(type)} />;
  };

  const SELECTIONS = [
    {
      id: 0,
      key: "published",
      title: "Publisert",
      display: (
        <TableWrapper className={loading ? "loading" : ""}>
          {renderList("published")}
        </TableWrapper>
      ),
    },
    {
      id: 1,
      key: "draft",
      title: "Utkast",
      display: (
        <TableWrapper className={loading ? "loading" : ""}>
          {renderList("draft")}
        </TableWrapper>
      ),
    },
  ];

  useEffect(() => {
    if (user?.uid) {
      fetchBlogpostsList();
    }
  }, [fetchBlogpostsList, user?.uid]);

  const performDeleteBlogposts = async () => {
    try {
      const deletion = await deleteBlogposts(deletions);
      if (deletion!.error === null) {
        dispatch(clearDeletionArray());
        closeDeleteModal();
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.transcripts.delete-multiple.success"
          )
        );
        fetchBlogpostsList();
      } else {
        sendErrorNotification(
          t(
            "dashboard.universal.notifications.transcripts.delete-multiple.failed"
          )
        );
      }
    } catch (e) {
      sendErrorNotification(
        t(
          "dashboard.universal.notifications.transcripts.delete-multiple.failed"
        )
      );
    }
  };

  const clickNewBlogpost = () => {
    navigate("/dashboard/admin/blogpost/new");
  };

  return (
    <PageContainer>
      <InnerPageContainer>
        <UpperRow>
          <TitleCol>
            <Title>Blogginnlegg</Title>
          </TitleCol>
          <ButtonCol>
            <Button
              onClick={clickNewBlogpost}
              variant="regular"
              color="blue"
              spacing="right"
              icon="plus"
            >
              Nytt innlegg
            </Button>
            <Button
              onClick={() => null}
              variant="regular"
              color="grey"
              icon="trash"
              disabled={deletions.length === 0}
            >
              Slett innlegg
            </Button>
          </ButtonCol>
        </UpperRow>
        <SearchContainer>
          <Input
            variant="search"
            value={searchQuery}
            placeholder={`${t(
              "dashboard.pages.transcripts.search-placeholder"
            )}..`}
            onChange={handleSearch}
          />
        </SearchContainer>
        <ContentContainer>
          <Tabs selections={SELECTIONS} />
        </ContentContainer>
      </InnerPageContainer>
      <DeleteModal
        title="Slett innlegg"
        text="Er du sikker på at du vil slette de valgte innleggene?"
        submitText="Ja, slett dem"
        onSubmit={performDeleteBlogposts}
      />
    </PageContainer>
  );
};

export default Index;
