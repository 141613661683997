import React from "react";
import {
  ContentContainer,
  ExitIconButton,
  ModalBody,
  ModalTitle,
  PopupModal,
} from "./styled";
import { Title } from "../../library/title";

interface IModalProps {
  onClose: any;
  open: any;
  title?: string;
  children: React.ReactNode;
}

const RegularModal: React.FC<IModalProps> = ({
  open,
  onClose,
  title,
  children,
}) => {
  return (
    <PopupModal size="lg" show={open} onHide={onClose}>
      <ModalTitle>
        <Title>{title}</Title>
        <ExitIconButton onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </ExitIconButton>
      </ModalTitle>
      <ModalBody>
        <ContentContainer>{children}</ContentContainer>
      </ModalBody>
    </PopupModal>
  );
};

export default RegularModal;
