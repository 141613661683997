const OVERLAY = {
  zIndex: 100,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
};

const CONTENT = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  border: "none",
};

export const renderSize = (width: number, windowWidth: number) => {
  if (windowWidth < 900) {
    return {
      content: {
        top: 70,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        width: "100%",
        padding: 10,
        zIndex: 9999,
        border: "none",
      },
    };
  }
  return {
    content: { ...CONTENT, maxWidth: `${width}px` },
    overlay: OVERLAY,
  };
};
