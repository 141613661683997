import * as type from "../types/blog";

interface BlogAction {
  type: string;
  blogposts: any[];
  loading: boolean;
}

const initialState = {
  blogposts: [],
  loadingList: true,
};

export const blog = (state = initialState, payload: BlogAction) => {
  switch (payload.type) {
    case type.SET_BLOGPOSTS_LIST:
      return {
        ...state,
        blogposts: payload.blogposts,
      };
    case type.CLEAR_BLOGPOSTS_LIST:
      return {
        ...state,
        blogposts: [],
      };
    case type.SET_BLOGPOSTS_LOADING:
      return {
        ...state,
        loadingList: payload.loading,
      };
    default:
      return state;
  }
};
