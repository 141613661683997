import styled from "@emotion/styled";

export const SearchContainer = styled.div`
  margin-top: 1.875rem;
  input {
    width: 100%;
    height: 3.125rem;
  }
`;

export const ListWrapper = styled.div<{
  fullPageHeight?: number;
  navigationHeight?: number;
  descriptionHeight?: number;
}>`
  display: block;
  position: relative;
  overflow-y: scroll;
  max-height: 350px;
  margin-top: 25px;

  @media (max-width: 975px) {
    //fullPageHeight - navigationHeight - descriptionHeight - titleHeight - modalPadding - searchInputHeight
    max-height: calc(
      ${({ fullPageHeight }) => fullPageHeight}px -
        ${({ navigationHeight }) => navigationHeight}px -
        ${({ descriptionHeight }) => descriptionHeight}px - 37px - 10px - 50px -
        160px
    ) !important;
  }
`;

export const AttentionBoxWrapper = styled.div`
  margin-bottom: 25px;
`;
