import ReactTimeAgo from "react-time-ago";
import Breadcrumb from "../../../../../../library/breadcrumb";
import {
  ButtonCol,
  ButtonsWrapper,
  DetailsWrapper,
  LastSavedLabel,
  TitleCol,
  TitleWrapper,
  TopWrapper,
  UpperRow,
  UpperTopWrapper,
} from "./styled";
import ExportDetails from "../../../../../../components/export-modal";
import { getIcon } from "../../../../../../utils/get-icon";
import { Title } from "../../../../../../library/title";
import Button from "../../../../../../library/button";
import Modal from "../../../../../../library/modal/new-modal";
import { Dispatch, SetStateAction, useState } from "react";

interface DesktopTopBarProps {
  onGoBack: () => void;
  onSave: () => void;
  onGenerateVideo: () => void;
  saveLoading: boolean;
  generateLoading: boolean;
  progress: number;
  lastUpdated?: number;
  transcriptId?: string;
  title?: string;
  captions: any;
  appearance: any;
  videoDownloadUrl?: string;
  setVideoDownloadUrl?: Dispatch<SetStateAction<string | undefined>>;
}

const DesktopTopBar = ({
  onGoBack,
  onSave,
  onGenerateVideo,
  saveLoading,
  generateLoading,
  lastUpdated,
  progress,
  title,
  transcriptId,
  captions,
  appearance,
  videoDownloadUrl,
  setVideoDownloadUrl,
}: DesktopTopBarProps) => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const openExportModal = () => {
    setShowExportModal(true);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    if (videoDownloadUrl) {
      setVideoDownloadUrl && setVideoDownloadUrl(undefined);
    }
  };

  const onExport = () => {
    openExportModal();
  };

  return (
    <>
      <TopWrapper>
        <UpperTopWrapper>
          <Breadcrumb title="Gå tilbake" onClick={onGoBack} />
          <LastSavedLabel>
            {saveLoading ? getIcon("spinner") : getIcon("cloud")}
            Lagret{" "}
            {lastUpdated && (
              <ReactTimeAgo locale="nb" date={new Date(lastUpdated * 1000)} />
            )}
          </LastSavedLabel>
        </UpperTopWrapper>
        <UpperRow>
          <TitleCol className="my-auto">
            <DetailsWrapper>
              <TitleWrapper>
                <Title>{title}</Title>
              </TitleWrapper>
            </DetailsWrapper>
          </TitleCol>
          <ButtonCol>
            <ButtonsWrapper>
              <Button
                onClick={onSave}
                icon="save"
                variant="regular"
                color="grey"
              >
                {saveLoading ? "Lagrer.." : "Lagre"}
              </Button>
              <Button
                onClick={onExport}
                icon="download"
                variant="regular"
                color="blue"
              >
                Last ned
              </Button>
            </ButtonsWrapper>
          </ButtonCol>
        </UpperRow>
      </TopWrapper>
      <Modal open={showExportModal} onClose={closeExportModal} title="Last ned">
        <ExportDetails
          onClose={closeExportModal}
          transcriptId={transcriptId ?? ""}
          captions={captions}
          configuration={appearance}
          onGenerateVideo={onGenerateVideo}
          generateLoading={generateLoading}
          variant="editor"
          progress={progress}
          videoDownloadUrl={videoDownloadUrl}
        />
      </Modal>
    </>
  );
};

export default DesktopTopBar;
