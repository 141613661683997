import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBlogposts, setBlogpostsLoading } from "../redux/actions/blog";
import { IState } from "../types";

const useFetchBlogposts = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state: IState) => state?.blog?.loadingList);

  const fetchBlogposts = useCallback(
    async (limit: number) => {
      try {
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/general/blogposts?limit=${limit}`,
          {
            method: "GET",
          }
        );
        const response = await request.json();
        if (response.error === null) {
          dispatch(setBlogpostsLoading(false));
          dispatch(setBlogposts(response.result));
        } else {
          throw response.error;
        }
      } catch (e) {
        dispatch(setBlogpostsLoading(false));
        throw e;
      }
    },
    [dispatch]
  );

  return { loading, fetchBlogposts };
};

export default useFetchBlogposts;
