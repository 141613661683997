import { t } from "i18next";
import { Label } from "../../../../library/label";
import Checkbox from "../../../../library/checkbox";
import { InputCol } from "./styled";
import Input from "../../../../library/input";
import { Link } from "react-router-dom";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { RegisterSchema } from "../../schema";

export const REGULAR_FIELDS = [
  {
    renderField: (
      register: UseFormRegister<RegisterSchema>,
      errors: FieldErrors
    ) => (
      <InputCol md={6}>
        <Label htmlFor="firstNameInput">
          {t("register.views.user-details.form.first-name.label")}
        </Label>
        <Input
          variant="regular"
          register={register}
          error={errors["firstName"]}
          id="firstNameInput"
          name="firstName"
          ariaLabel={
            t("register.views.user-details.form.first-name.ariaLabel") as string
          }
          placeholder={
            t(
              "register.views.user-details.form.first-name.placeholder"
            ) as string
          }
        />
      </InputCol>
    ),
  },
  {
    renderField: (
      register: UseFormRegister<RegisterSchema>,
      errors: FieldErrors
    ) => (
      <InputCol md={6}>
        <Label htmlFor="lastNameInput">
          {t("register.views.user-details.form.last-name.label")}
        </Label>
        <Input
          variant="regular"
          register={register}
          error={errors["lastName"]}
          id="lastNameInput"
          name="lastName"
          ariaLabel={
            t("register.views.user-details.form.last-name.ariaLabel") as string
          }
          placeholder={
            t(
              "register.views.user-details.form.last-name.placeholder"
            ) as string
          }
        />
      </InputCol>
    ),
  },
  {
    renderField: (
      register: UseFormRegister<RegisterSchema>,
      errors: FieldErrors
    ) => (
      <InputCol md={6}>
        <Label htmlFor="emailInput">
          {t("register.views.user-details.form.email.label")}
        </Label>
        <Input
          variant="regular"
          register={register}
          error={errors["email"]}
          id="emailInput"
          name="email"
          ariaLabel={
            t("register.views.user-details.form.email.ariaLabel") as string
          }
          placeholder={
            t("register.views.user-details.form.email.placeholder") as string
          }
        />
      </InputCol>
    ),
  },
  {
    renderField: (
      register: UseFormRegister<RegisterSchema>,
      errors: FieldErrors
    ) => (
      <InputCol md={6}>
        <Label htmlFor="phoneInput">
          {t("register.views.user-details.form.phone.label")}
        </Label>
        <Input
          variant="regular"
          register={register}
          error={errors["phone"]}
          id="phoneInput"
          name="phone"
          ariaLabel={
            t("register.views.user-details.form.phone.ariaLabel") as string
          }
          placeholder={
            t("register.views.user-details.form.phone.placeholder") as string
          }
        />
      </InputCol>
    ),
  },
  {
    renderField: (
      register: UseFormRegister<RegisterSchema>,
      errors: FieldErrors
    ) => (
      <InputCol md={6}>
        <Label htmlFor="passwordInput">
          {t("register.views.user-details.form.password.label")}
        </Label>
        <Input
          variant="regular"
          register={register}
          error={errors["password"]}
          name="password"
          id="passwordInput"
          ariaLabel={
            t("register.views.user-details.form.password.ariaLabel") as string
          }
          placeholder={
            t("register.views.user-details.form.password.label") as string
          }
          type="password"
        />
      </InputCol>
    ),
  },
  {
    renderField: (
      register: UseFormRegister<RegisterSchema>,
      errors: FieldErrors
    ) => (
      <InputCol md={6}>
        <Label htmlFor="retypePasswordInput">
          {t("register.views.user-details.form.retype-password.label")}
        </Label>
        <Input
          variant="regular"
          name="passwordRetype"
          id="retypePasswordInput"
          register={register}
          error={errors["passwordRetype"]}
          ariaLabel={
            t(
              "register.views.user-details.form.retype-password.ariaLabel"
            ) as string
          }
          placeholder={
            t(
              "register.views.user-details.form.retype-password.placeholder"
            ) as string
          }
          type="password"
        />
      </InputCol>
    ),
  },
  {
    renderField: (
      register: UseFormRegister<RegisterSchema>,
      errors: FieldErrors,
      setValue?: any,
      options?: any
    ) => (
      <InputCol md={12}>
        <Checkbox
          checked={options?.acceptedTerms}
          onChange={(e: any) => setValue("acceptedTerms", e.target.checked)}
          name="product-select"
          error={errors["acceptedTerms"]}
          ariaLabel={
            t("register.views.user-details.form.checkbox.ariaLabel") as string
          }
          label={
            <>
              {t("register.views.user-details.form.checkbox.label")}{" "}
              <Link target="_blank" to="/terms">
                Les våre brukervilkår
              </Link>{" "}
              og{" "}
              <Link target="_blank" to="/privacy-policy">
                personvernerklæring
              </Link>
              .
            </>
          }
        />
      </InputCol>
    ),
  },
];
