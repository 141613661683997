import { useTranslation } from "react-i18next";
import { Label } from "../../../../../../../library/label";
import Switch from "../../../../../../../library/switch";
import { BottomWrapper, Field, SmallText, Wrapper } from "./styled";
import Input from "../../../../../../../library/input";
import Textarea from "../../../../../../../library/textarea";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { UploadSoundtrackSchema } from "../../schema";

interface InputFieldsProps {
  setValue: UseFormSetValue<UploadSoundtrackSchema>;
  isFilePublic: boolean;
  register: UseFormRegister<UploadSoundtrackSchema>;
  errors: FieldErrors;
  composedText: string;
}

const InputFields = ({
  setValue,
  isFilePublic,
  register,
  errors,
  composedText,
}: InputFieldsProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Field>
        <Label>
          {t(
            "dashboard.pages.soundtracks.add-modal.content.compose-step.form-input.text-label"
          )}
        </Label>
        <Textarea
          register={register}
          name="text"
          error={errors["text"]}
          rows={10}
          maxLength={4000}
          ariaLabel={
            t(
              "dashboard.pages.soundtracks.add-modal.content.compose-step.form-input.text-input.ariaLabel"
            ) as string
          }
        />
        <BottomWrapper>
          <SmallText>{composedText?.length ?? 0}/4000</SmallText>
        </BottomWrapper>
      </Field>
      <Field>
        <Label htmlFor="titleInput">
          {t(
            "dashboard.pages.soundtracks.add-modal.content.compose-step.form-input.title-label"
          )}
        </Label>
        <Input
          variant="regular"
          name="title"
          id="titleInput"
          register={register}
          error={errors["title"]}
          ariaLabel={
            t(
              "dashboard.pages.soundtracks.add-modal.content.compose-step.form-input.title-input.ariaLabel"
            )!
          }
          placeholder={
            t(
              "dashboard.pages.soundtracks.add-modal.content.compose-step.form-input.title-input.placeholder"
            )!
          }
        />
      </Field>
      <Field>
        <Switch
          checked={isFilePublic}
          onChange={(value: boolean) => setValue("public", value)}
          ariaLabel={
            isFilePublic
              ? t(
                  "dashboard.pages.soundtracks.add-modal.content.compose-step.share-toggle.toggled.ariaLabel"
                )!
              : t(
                  "dashboard.pages.soundtracks.add-modal.content.compose-step.share-toggle.not-toggled.ariaLabel"
                )!
          }
          label={
            t(
              "dashboard.pages.soundtracks.add-modal.content.compose-step.share-toggle.label"
            ) as string
          }
          helpingText={
            t(
              "dashboard.pages.soundtracks.add-modal.content.compose-step.share-toggle.tooltip"
            ) as string
          }
        />
      </Field>
    </Wrapper>
  );
};

export default InputFields;
