import { Dispatch, SetStateAction } from "react";
import DesktopTopBar from "./desktop";
import MobileTopBar from "./mobile";

interface TopBarProps {
  onGoBack: () => void;
  onSave: () => void;
  onGenerateVideo: () => void;
  windowWidth: number;
  mobileView: number;
  saveLoading: boolean;
  generateLoading: boolean;
  progress: number;
  transcript: any;
  appearance: any;
  captions: any;
  videoDownloadUrl?: string;
  setVideoDownloadUrl?: Dispatch<SetStateAction<string | undefined>>;
  navigationHeight?: number;
}

const TopBar = ({
  onGoBack,
  onSave,
  onGenerateVideo,
  saveLoading,
  generateLoading,
  progress,
  transcript,
  mobileView,
  windowWidth,
  appearance,
  captions,
  videoDownloadUrl,
  setVideoDownloadUrl,
  navigationHeight,
}: TopBarProps) => {
  return windowWidth > mobileView ? (
    <DesktopTopBar
      onGoBack={onGoBack}
      lastUpdated={transcript?.lastUpdated}
      saveLoading={saveLoading}
      generateLoading={generateLoading}
      title={transcript?.filename}
      onSave={onSave}
      progress={progress}
      onGenerateVideo={onGenerateVideo}
      transcriptId={transcript?.id}
      captions={captions}
      appearance={appearance}
      videoDownloadUrl={videoDownloadUrl}
      setVideoDownloadUrl={setVideoDownloadUrl}
    />
  ) : (
    <MobileTopBar
      onGoBack={onGoBack}
      onSave={onSave}
      saveLoading={saveLoading}
      appearance={appearance}
      captions={captions}
      transcriptId={transcript?.id}
      onGenerateVideo={onGenerateVideo}
      generateLoading={generateLoading}
      progress={progress}
      videoDownloadUrl={videoDownloadUrl}
      setVideoDownloadUrl={setVideoDownloadUrl}
      navigationHeight={navigationHeight}
    />
  );
};

export default TopBar;
