import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const AppearanceWrapper = styled.div`
  padding-top: 20px;
  overflow-x: hidden;
  @media (max-width: 975px) {
    padding-top: 50px;
  }
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input,
  textarea {
    width: 100%;
    margin-top: 0.3125rem;
  }
  &:last-of-type {
    margin-bottom: 0rem;
  }
  &:first-of-type > div > div > div > div {
    margin-top: 0.3125rem;
    background-color: #f4f4f4;
    height: 49px;
  }

  .sketch-picker {
    border-radius: 10px !important;
    overflow: hidden;
    margin-top: 0.3125rem;
    min-width: 100%;
    box-shadow: none !important;
    padding: 0px !important;
    margin-bottom: 80px;
    .flexbox-fix {
      background-color: #f4f4f4;
      padding: 10px 20px !important;
      &:last-of-type {
        padding-left: 30px !important;
        padding-right: 30px !important;
        padding-top: 15px !important;
        padding-bottom: 5px !important;
      }
    }
    & > div {
      padding-bottom: 200px !important;
      & > div {
        max-height: 200px !important;
      }
    }
  }
`;

export const InputRow = styled(Row)`
  row-gap: 0.9375rem;
`;

export const InputCol = styled(Col)`
  a {
    font-family: Poppins, sans-serif;
    font-size: 14px;
  }
  & > div > div {
    width: 100%;
  }
`;
