import styled from "@emotion/styled";
import { Row, Col } from "react-bootstrap";

export const SelectionsWrapper = styled.div`
  &.variant-video {
    max-height: 425px;
    overflow-y: scroll;
  }
`;

export const SingleSelection = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 1rem;
  border: 0.125rem dashed #e1e1e1;
  padding: 1.875rem 0.625rem;
  text-align: center;
  position: relative;
  margin-bottom: 0.625rem;

  &:last-of-type {
    margin-bottom: 0rem;
  }
  &.active {
    border: 0.125rem dashed #2f8de4;
    background-color: #e5f0f9;
  }
  &.variant-video {
    padding: 1.275rem 0.325rem;
  }
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding: 1.675rem 0.625rem;
  }
`;

export const SelectionRow = styled(Row)``;

export const SelectionCol = styled(Col)`
  &.badge {
    max-width: 150px;
  }
  &.thumbnail-col {
    max-width: 170px;
    padding-right: 0px !important;
  }
  &.button-col {
    max-width: 4.375rem;
  }
  &.details-col {
    text-align: left;
    &.audio {
      padding-left: 25px;
      max-width: 8rem;
    }
    &.video {
      padding-left: 0px !important;
      display: block;
    }
  }
  &.icon-col {
    max-width: 100px;
  }
  &.price-col {
    text-align: left;
    max-width: 7.5rem;
  }
  &.audio-col {
    padding-right: 25px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  @media (max-width: 768px) {
    &.button-col {
      padding-right: 0px;
      max-width: 4rem;
    }
  }
`;

export const SelectButton = styled.button`
  border-radius: 100%;
  height: 1.5625rem;
  width: 1.5625rem;
  font-size: 1.375rem;
  padding: 0rem;
  margin: 0rem;
  justify-content: center;
  color: #2f8de4;
  border: none;
  background: transparent;
`;

export const SelectionTitle = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 600;
  &.audio {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const SelectionDescription = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: grey;
  font-size: 0.875rem;
`;

export const SelectionPrice = styled.span`
  font-family: "Poppins", sans-serif;
  color: #3b3b3b;
  font-weight: 600;
  display: block;
`;

export const SelectionPriceDescription = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: grey;
  font-size: 0.875rem;
`;

export const SelectIcon = styled.span`
  font-size: 26px;
  color: grey;
  text-align: center;
`;

export const SelectionDetails = styled.small`
  color: #2f8de4;
  font-family: "Poppins", sans-serif;
  display: block;
  font-weight: 400;
  font-size: 12px;
`;

export const Thumbnail = styled.img`
  border-radius: 8px;
`;
