import * as type from "../types/blogposts";

const initialState = {
  list: {
    published: [],
    draft: [],
  },
  deletionArray: [],
  loadingList: true,
  searchQuery: null,
};

export const blogposts = (state = initialState, payload: any) => {
  switch (payload.type) {
    case type.SET_BLOGPOSTS_LIST:
      return {
        ...state,
        list: payload.blogposts,
      };
    case type.CLEAR_BLOGPOSTS_LIST:
      return {
        ...state,
        list: [],
      };
    case type.SET_BLOGPOSTS_LOADING:
      return {
        ...state,
        loadingList: payload.loading,
      };
    case type.SET_BLOGPOSTS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: payload.query !== "" ? payload.query : null,
      };
    case type.CLEAR_BLOGPOSTS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: null,
      };
    case type.APPEND_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: [...state.deletionArray, payload.id],
      };
    case type.SUBTRACT_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: state.deletionArray.filter(
          (existing) => existing !== payload.id
        ),
      };
    case type.CLEAR_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: [],
      };
    default:
      return state;
  }
};
