import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import SignupForm from "./signup-form";
import {
  Container,
  ContentWrapper,
  DetailsCol,
  DetailsRow,
  DetailsTitle,
  PageCol,
  PageRow,
  RegisterWrapper,
} from "./styled";

const Content = () => {
  return (
    <Container>
      <PageRow>
        <PageCol md={7}>
          <ContentWrapper>
            <Title>
              Lær å lage undertekst til video helt automatisk og spar deg for
              masse manuelt arbeid
            </Title>
            <DetailsRow>
              <DetailsCol>
                <DetailsTitle>Dato og tid</DetailsTitle>
                <Text color="dark">14. august klokken 14:00</Text>
              </DetailsCol>
              <DetailsCol>
                <DetailsTitle>Lengde</DetailsTitle>
                <Text color="dark">45 minutter</Text>
              </DetailsCol>
              <DetailsCol>
                <DetailsTitle>Plattform</DetailsTitle>
                <Text color="dark">Zoom</Text>
              </DetailsCol>
            </DetailsRow>
            <Text color="dark">
              Webinar: Vi inviterer til gratis innføringskurs i hvordan du
              tekster video med Randi. Kurset er for deg som ønsker å komme i
              gang med å lage undertekst til videoene dine, men som trenger en
              kort veiledning i hvordan man gjør dette i praksis. Du vil bli
              overrasket over hvor effektivt dette kan gjøres og over hvor mye
              tid du kan spare på å tekste automatisk!
              <br />
              <br />
              Vi tar deg gjennom prosessen med å tekste en video, trinn for
              trinn. Gjennom webinaret lærer du å:
              <br />
              <br />
              <ul>
                <li>laste opp videofiler til Randi</li>
                <li>velge tekstformat tilpasset din video og ditt formål</li>
                <li>redigere og endre utseende på underteksten</li>
                <li>legge til undertekst-filen i videoplattformen din</li>
                <li>se og vurdere det ferdige resultatet</li>
              </ul>
              I tillegg vil vi sette av tid til å svare på alle de ulike
              spørsmålene du måtte ha om Randi. Vel møtt til et lærerikt
              webinar!
            </Text>
          </ContentWrapper>
        </PageCol>
        <PageCol md={5}>
          <RegisterWrapper>
            <SignupForm />
          </RegisterWrapper>
        </PageCol>
      </PageRow>
    </Container>
  );
};

export default Content;
