import styled from "@emotion/styled";

export const TimelineContainer = styled.div<{ playerHeight?: number }>`
  max-height: calc(100vh - 89.94px - 95px - 80px - 70px);
  max-width: 670px;
  padding-top: 20px;
  overflow-y: scroll;
  @media (min-width: 675px) {
    min-width: 100%;
    max-width: unset !important;
  }

  @media (max-width: 975px) {
    max-height: unset !important;
    overflow-y: unset;
    padding-top: 50px;
  }
`;
