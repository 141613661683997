export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";

export const SET_LOADING_WORKSPACES = "SET_LOADING_WORKSPACES";
export const SET_LOADING_USER_DETAILS = "SET_LOADING_USER_DETAILS";

export const SET_USER_WORKSPACES = "SET_USER_WORKSPACES";
export const CLEAR_USER_WORKSPACES = "CLEAR_USER_WORKSPACES";

export const SET_ACTIVE_WORKSPACE = "SET_ACTIVE_WORKSPACE";
export const CLEAR_ACTIVE_WORKSPACE = "CLEAR_ACTIVE_WORKSPACE";

export const UPDATE_WORKSPACE_SUBSCRIPTION = "UPDATE_WORKSPACE_SUBSCRIPTION";

export const CLEAR_USER_STATE = "CLEAR_USER_STATE";

export const SET_USER_ONBOARDED = "SET_USER_ONBOARDED";
