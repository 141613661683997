import styled from "@emotion/styled";
import { Modal } from "react-bootstrap";

export const PopupModal = styled(Modal)`
  .modal-dialog.modal-lg {
    max-width: 35.9375rem;
  }
  .modal-content {
    padding: 1.25rem;
  }
`;

export const ModalTitle = styled(Modal.Title)`
  text-align: center;
  padding-top: 0.625rem;
`;

export const NewModalTitle = styled.div`
  text-align: center;
  padding-top: 0.625rem;
`;

export const Title = styled.h2`
  text-align: center;
  margin-top: 1.5625rem;
  font-family: NexaBold;
  font-size: 2rem;
`;

export const Description = styled.span`
  color: grey;
  font-family: NexaLight;
  font-size: 1rem;
  max-width: 25rem;
  text-align: center;
  margin: 0 auto;
  display: block;
`;

export const ExitIconButton = styled.button`
  position: absolute;
  right: 1.4rem;
  top: 1.25rem;
  border: none;
  color: grey;
  background-color: transparent;
  font-size: 1.25rem;
  opacity: 0.38;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
`;

export const ModalBody = styled(Modal.Body)``;

export const NewModalBody = styled.div``;

export const ContentContainer = styled.div`
  padding-top: 0.625rem;
  margin-left: 0.1563rem;
  margin-right: 0.1563rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  .input-field {
    margin-bottom: 1.25rem;
  }
`;
