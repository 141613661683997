import * as type from "../types/user";

export const setUserCredentials = (payload: any) => {
  return {
    type: type.SET_USER_DETAILS,
    details: payload,
  };
};

export const setLoadingUserDetails = (payload: any) => {
  return {
    type: type.SET_LOADING_USER_DETAILS,
    loading: payload,
  };
};

export const setUserWorkspaces = (payload: any) => {
  return {
    type: type.SET_USER_WORKSPACES,
    workspaces: payload,
  };
};

export const setLoadingWorkspaces = (payload: any) => {
  return {
    type: type.SET_LOADING_WORKSPACES,
    loading: payload,
  };
};

export const setActiveWorkspace = (payload: any) => {
  return {
    type: type.SET_ACTIVE_WORKSPACE,
    workspace: payload,
  };
};

export const clearUserState = () => {
  return {
    type: type.CLEAR_USER_STATE,
  };
};

export const updateWorkspaceSubscription = (payload: any) => {
  return {
    type: type.UPDATE_WORKSPACE_SUBSCRIPTION,
    result: payload,
  };
};

export const setUserOnboarded = () => {
  return {
    type: type.SET_USER_ONBOARDED,
  };
};
