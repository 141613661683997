export const REQUEST_CREATE_SOUNDTRACK = "REQUEST_CREATE_SOUNDTRACK";
export const GRANT_CREATE_SOUNDTRACK = "GRANT_CREATE_SOUNDTRACK";
export const REVOKE_CREATE_SOUNDTRACK = "REVOKE_CREATE_SOUNDTRACK";
export const CLEAR_CREATE_SOUNDTRACK = "CLEAR_CREATE_SOUNDTRACK";

export const SET_SOUNDTRACKS_LIST = "SET_SOUNDTRACKS_LIST";
export const CLEAR_SOUNDTRACKS_LIST = "CLEAR_SOUNDTRACKS_LIST";

export const SET_SOUNDTRACKS_LIST_LOADING = "SET_SOUNDTRACKS_LIST_LOADING";
export const SET_SOUNDTRACK_CREATION_LOADING =
  "SET_SOUNDTRACK_CREATION_LOADING";

export const SET_SOUNDTRACKS_SEARCH_QUERY = "SET_SOUNDTRACKS_SEARCH_QUERY";
export const CLEAR_SOUNDTRACKS_SEARCH_QUERY = "CLEAR_SOUNDTRACKS_SEARCH_QUERY";

export const APPEND_DELETION_ARRAY = "APPEND_DELETION_ARRAY";
export const SUBTRACT_DELETION_ARRAY = "SUBTRACT_DELETION_ARRAY";
export const CLEAR_DELETION_ARRAY = "CLEAR_DELETION_ARRAY";

export const SET_SOUNDTRACK_STATUS = "SET_SOUNDTRACK_STATUS";
