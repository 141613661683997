import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setArticle, setArticleLoading } from "../redux/actions/article";
import { IState } from "../types";

const useBlogpost = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state: IState) => state.article.loadingArticle);

  const fetchBlogpost = useCallback(
    async (prefix: string) => {
      try {
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/general/blogpost?prefix=${prefix}`,
          {
            method: "GET",
          }
        );
        const response = await request.json();
        if (response.error === null) {
          dispatch(setArticleLoading(false));
          dispatch(setArticle(response.result));
        } else {
          throw response.error;
        }
      } catch (e) {
        dispatch(setArticleLoading(false));
        throw e;
      }
    },
    [dispatch]
  );

  return { loading, fetchBlogpost };
};

export default useBlogpost;
