import styled from "@emotion/styled";

export const MobileWrapper = styled.div<{
  playerHeight?: number;
  navigationHeight?: number;
}>`
  position: fixed;
  max-height: calc(
    100svh - ${({ playerHeight }) => playerHeight}px -
      ${({ navigationHeight }) => navigationHeight}px
  );
  width: 100%;
  background-color: #fff;
  margin-top: ${({ playerHeight }) => playerHeight}px;
  padding-bottom: 75px;
  overflow-y: scroll;
  z-index: 3;
`;

export const MenuWrapper = styled.div<{ playerHeight?: number }>`
  display: flex;
  border-bottom: 0.125rem solid rgb(244, 244, 244);
  position: fixed;
  background-color: #fff;
  width: 100%;
  margin-top: -10px;
  padding-top: 2.5px;
  z-index: 11;
`;

export const MenuButton = styled.button`
  border: none;
  background: none;
  padding: 7.5px 10px;
  font-family: "Poppins"; sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: grey;
  &.active{
    color: #2f8de4 !important;
  }
  &:hover{
    color: #2f8de4 !important;
  }
`;

export const ContentWrapper = styled.div<{
  playerHeight?: number;
  fullPageHeight?: number;
}>`
  position: relative;
  padding: 0px 15px;
`;
