import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMembersList,
  setLoadingMembersList,
} from "../redux/actions/workspace";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { IState } from "../types";

const useMembersList = () => {
  const auth = getAuth(firebaseApp);
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: IState) => state.workspace.loadingMembers
  );
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const fetchMembersList = useCallback(async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      dispatch(setLoadingMembersList(true));
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/members?workspaceId=${selectedWorkspace.id}&token=${token}`,
        {
          method: "GET",
        }
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(setLoadingMembersList(false));
        dispatch(setMembersList(response.result));
      } else {
        throw response.error;
      }
    } catch (e) {
      dispatch(setLoadingMembersList(false));
      dispatch(setMembersList([]));
      throw e;
    }
  }, [dispatch, auth.currentUser, selectedWorkspace.id]);
  return { fetchMembersList, loading };
};

export default useMembersList;
