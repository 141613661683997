import * as type from "../types/workspace";

export const setDetails = (payload: any) => {
  return {
    type: type.SET_WORKSPACE_DETAILS,
    details: payload,
  };
};

export const setLoadingDetails = (payload: any) => {
  return {
    type: type.SET_LOADING_WORKSPACE_DETAILS,
    loading: payload,
  };
};

export const setMembersList = (payload: any) => {
  return {
    type: type.SET_MEMBERS_LIST,
    members: payload,
  };
};

export const setLoadingMembersList = (payload: any) => {
  return {
    type: type.SET_LOADING_MEMBERS_LIST,
    loading: payload,
  };
};
