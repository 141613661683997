import DashboardLayout from "../../../components/dashboard-layout";
import Content from "./content";

const Account = () => {
  return (
    <DashboardLayout>
      <Content />
    </DashboardLayout>
  );
};

export default Account;
