import { useCallback, useState } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../types";
import {
  setSaveLoadingTranscript,
  setTranscriptUpdate,
} from "../redux/actions/transcript";
import { sendErrorNotification } from "../library/notification";
import { setActiveBlogpost } from "../redux/actions/blogpost";
import { EditBlogpostSchema } from "../pages/dashboard/edit-blogpost/content/schema";

const useFetchAdminBlogpost = () => {
  const dispatch = useDispatch();
  const auth = getAuth(firebaseApp);
  const [loading, setLoading] = useState<boolean>(true);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const getAdminBlogpost = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/admin/blogpost?token=${token}&id=${id}`,
          {
            method: "GET",
          }
        );
        const response = await request.json();
        if (response.error === null) {
          dispatch(setActiveBlogpost(response.data));
          setLoading(false);
        } else {
          throw response.error;
        }
      } catch (e) {
        setLoading(false);
      }
    },
    [auth, dispatch]
  );

  const updateAdminBlogpost = useCallback(
    async (data: EditBlogpostSchema) => {
      try {
        dispatch(setSaveLoadingTranscript(true));
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/transcript/update`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: token,
              workspaceId: selectedWorkspace?.id,
              data: { content: data?.content, id: data?.id },
            }),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          //TODO Fix this
          dispatch(setSaveLoadingTranscript(false));
          dispatch(setTranscriptUpdate(response?.timestamp));
          return;
        } else {
          throw response.error;
        }
      } catch (e) {
        dispatch(setSaveLoadingTranscript(false));
        sendErrorNotification("Kunne ikke lagre transkript.");
      }
    },
    [auth?.currentUser, selectedWorkspace?.id, dispatch]
  );
  return { getAdminBlogpost, loading, updateAdminBlogpost };
};

export default useFetchAdminBlogpost;
