import * as type from "../types/blogpost";

const initialState = {
  saveLoading: false,
  data: {
    content: "",
    excerpt: "",
    prefix: "",
    status: "",
    thumbnail: "",
    timestamp: "",
    title: "",
    id: null,
  },
};

export const blogpost = (state = initialState, payload: any) => {
  switch (payload.type) {
    case type.SET_ACTIVE_BLOGPOST:
      return {
        ...state,
        data: payload.data,
      };
    case type.CLEAR_ACTIVE_BLOGPOST:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};
