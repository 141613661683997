import { useTranslation } from "react-i18next";
import { Label } from "../../../../../../library/label";
import { Select } from "../../../../../../library/select";
import { Text } from "../../../../../../library/text";
import { languages } from "../languages";
import {
  DetailCol,
  DetailsRow,
  Field,
  SelectionWrapper,
  SmallText,
  Thumbnail,
  ButtonsWrapper,
} from "./styled";
import thumbnail from "../../../../../../assets/img/translation.png";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Title } from "../../../../../../library/title";
import Button from "../../../../../../library/button";
import { UseFormSetValue } from "react-hook-form";
import { UploadTranscriptSchema } from "../schema";

interface SelectLanguageViewProps {
  setStep: Dispatch<SetStateAction<2 | 1 | 3 | 4>>;
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  selectedLanguage: string;
}

const SelectLanguageView: React.FC<SelectLanguageViewProps> = ({
  setStep,
  setValue,
  selectedLanguage,
}) => {
  const { t } = useTranslation();

  const handleSelection = (language: string) => {
    setValue("language", language);
  };

  const clickBack = () => {
    setStep(1);
  };

  const clickNext = () => {
    setStep(3);
  };

  return (
    <SelectionWrapper>
      <DetailsRow>
        <DetailCol xs={3} className="my-auto">
          <Thumbnail src={thumbnail} />
        </DetailCol>
        <DetailCol>
          <Title size="xs">
            {t(
              "dashboard.pages.transcripts.add-modal.content.language-step.title"
            )}
          </Title>
          <Text size="small" color="dark">
            {t(
              "dashboard.pages.transcripts.add-modal.content.language-step.description"
            )}
          </Text>
        </DetailCol>
      </DetailsRow>
      <Field>
        <Label htmlFor="formatSelect">
          {t(
            "dashboard.pages.transcripts.add-modal.content.language-step.input.label"
          )}
        </Label>
        <Select
          items={languages}
          onChange={(v: ChangeEvent<HTMLSelectElement>) =>
            handleSelection(v.target.value)
          }
          selected={selectedLanguage}
          id="formatSelect"
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.language-step.input.input-field.ariaLabel"
            ) as string
          }
        />
        <SmallText>
          {t(
            "dashboard.pages.transcripts.add-modal.content.language-step.input.small-text"
          )}
        </SmallText>
      </Field>
      <ButtonsWrapper>
        <Button
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.language-step.buttons.back.ariaLabel"
            ) as string
          }
          onClick={clickBack}
          variant="regular"
          color="grey"
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.language-step.buttons.back.title"
          )}
        </Button>
        <Button
          type="submit"
          variant="regular"
          color="blue"
          onClick={clickNext}
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.language-step.buttons.next.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.language-step.buttons.next.title"
          )}
        </Button>
      </ButtonsWrapper>
    </SelectionWrapper>
  );
};

export default SelectLanguageView;
