import Footer from "../../components/footer";
import Content from "./content";
import Navigation from "../../components/navigation";
import useBlogpost from "../../hooks/useBlogpost";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { IState } from "../../types";
import Meta from "./meta";

const Index = () => {
  const { id } = useParams();
  const { fetchBlogpost, loading } = useBlogpost();
  const content = useSelector((state: IState) => state.article.content);

  useEffect(() => {
    if (id) fetchBlogpost(id);
  }, [id, fetchBlogpost]);

  return (
    <>
      <Meta content={content} />
      <Navigation background={true} />
      <Content loading={loading} content={content} />
      <Footer />
    </>
  );
};

export default Index;
