import { useTranslation } from "react-i18next";
import Switch from "../../../../../../../library/switch";
import { Field, Wrapper } from "./styled";
import { UseFormSetValue } from "react-hook-form";
import { UploadTranscriptSchema } from "../../schema";

interface InputFieldsProps {
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  isFilePublic: boolean;
}

const InputFields = ({ setValue, isFilePublic }: InputFieldsProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Field>
        <Switch
          checked={isFilePublic}
          onChange={(value: boolean) => setValue("public", value)}
          ariaLabel={
            isFilePublic
              ? t(
                  "dashboard.pages.transcripts.add-modal.content.source-step.share-toggle.toggled.ariaLabel"
                )!
              : t(
                  "dashboard.pages.transcripts.add-modal.content.source-step.share-toggle.not-toggled.ariaLabel"
                )!
          }
          label={
            t(
              "dashboard.pages.transcripts.add-modal.content.source-step.share-toggle.label"
            ) as string
          }
          helpingText={
            t(
              "dashboard.pages.transcripts.add-modal.content.source-step.share-toggle.tooltip"
            ) as string
          }
        />
      </Field>
    </Wrapper>
  );
};

export default InputFields;
