import { useCallback, useEffect, useState } from "react";
import Button from "../../library/button";
import {
  CarouselItem,
  CarouselWrapper,
  ModalButtonsContainer,
  OnboardingContentWrapper,
  OnboardingModalContainer,
} from "./styled";
import ProgressBar from "./progressBar";
import IntroductionStep from "./steps/introduction";
import TranscriptionStep from "./steps/transcription";
import EditSubtitleStep from "./steps/edit-subtitles";
import DownloadStep from "./steps/download";
import useOnboarding from "../../hooks/useOnboarding";
import { IState } from "../../types";
import { useSelector } from "react-redux";

const STEPS = [
  <IntroductionStep />,
  <TranscriptionStep />,
  <EditSubtitleStep />,
  <DownloadStep />,
];

type OnboardingModalProps = {
  onClose: () => void;
};

const Index = ({ onClose }: OnboardingModalProps) => {
  const { setUserOnboard } = useOnboarding();
  const user = useSelector((state: IState) => state?.user?.details);
  const [step, setStep] = useState<number>(1);

  const clickGoBack = useCallback(() => {
    switch (step) {
      case 1:
        onClose();
        break;
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      default:
        setStep(3);
        break;
    }
  }, [onClose, step]);

  const clickGoNext = useCallback(() => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      case 3:
        setStep(4);
        break;
      default:
        onClose();
        break;
    }
  }, [onClose, step]);

  const renderPreviousButtonText = () => {
    return step === 1 ? "Jeg står over!" : "Gå tilbake";
  };

  const renderNextButtonText = useCallback(() => {
    switch (step) {
      case 1:
        return "Ja, vis meg rundt!";
      case 2:
        return "Gå videre";
      case 3:
        return "Gå videre";
      default:
        return "Fullfør gjennomgang";
    }
  }, [step]);

  useEffect(() => {
    if (!user?.onboarded) {
      setUserOnboard();
    }
  }, [setUserOnboard, user?.onboarded]);

  return (
    <OnboardingModalContainer>
      <ProgressBar step={step} setStep={setStep} />
      <OnboardingContentWrapper>
        <CarouselWrapper
          activeIndex={step - 1}
          interval={null}
          indicators={false}
          controls={false}
        >
          {STEPS.map((step) => (
            <CarouselItem>{step}</CarouselItem>
          ))}
        </CarouselWrapper>
        <ModalButtonsContainer>
          <Button onClick={clickGoBack} color="grey" variant="regular">
            {renderPreviousButtonText()}
          </Button>
          <Button onClick={clickGoNext} color="blue" variant="regular">
            {renderNextButtonText()}
          </Button>
        </ModalButtonsContainer>
      </OnboardingContentWrapper>
    </OnboardingModalContainer>
  );
};

export default Index;
