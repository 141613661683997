import ProgressBar from "../../library/progress-bar";
import { getIcon } from "../../utils/get-icon";
import {
  ProgressBarWrap,
  ProgressWrapper,
  StepIcon,
  StepTitle,
  StepWrapper,
} from "./styled";

const STEPS = ["Introduksjon", "Transkribering", "Redigering", "Nedlasting"];

type IndexProps = {
  step: number;
  setStep: any;
};

const Index = ({ step, setStep }: IndexProps) => {
  const getPercentage = (step: number) => {
    switch (step) {
      case 1:
        return 0;
      case 2:
        return 33;
      case 3:
        return 66;
      case 4:
        return 100;
      default:
        return 0;
    }
  };

  return (
    <ProgressBarWrap>
      <ProgressBar
        className="full-progress-bar"
        percentage={getPercentage(step)}
        color="progress2"
        size="sm"
      />
      <ProgressWrapper>
        {STEPS.map((title, index) => (
          <StepWrapper>
            <StepIcon className={step >= index + 1 ? "active" : ""}>
              {getIcon("selected")}
            </StepIcon>
            <StepTitle>{title}</StepTitle>
          </StepWrapper>
        ))}
      </ProgressWrapper>
    </ProgressBarWrap>
  );
};

export default Index;
