import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const PageContainer = styled.div`
  margin-top: 0rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
  position: relative;
  @media (max-width: 975px) {
    padding-top: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  @media (max-width: 767px) {
    padding-top: 3.5rem;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 10px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  position: relative;
  padding: 1.875rem;
  padding-top: 0rem;
  overflow-x: hidden;
  @media (max-width: 975px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const AddButton = styled.button`
  padding: 0.75rem 1.5625rem;
  font-family: NexaBold;
  background-color: #56bea3;
  border: none;
  color: #fff;
  font-size: 1rem;
  border-radius: 0.625rem;
  margin-right: 0.9375rem;
`;

export const DeleteButton = styled.button`
  padding: 0.75rem 1.5625rem;
  font-family: NexaBold;
  background-color: #f2f2f2;
  color: grey;
  border: none;
  font-size: 1rem;
  border-radius: 0.625rem;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const ToggleButtonWrapper = styled.div`
  text-align: center;
`;

export const ToggleGroupContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.4375rem 0.3125rem;
  border: 0.0625rem solid #f4f4f4;
  border-radius: 0.875rem;
`;

export const ToggleButton = styled.button`
  border: none;
  margin-left: 0.1563rem;
  margin-right: 0.1563rem;
  padding: 0.625rem 1.5625rem;
  border-radius: 0.75rem;
  background: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: grey;
  font-size: 0.875rem;
  &.active {
    color: grey;
    background-color: #f4f4f4;
  }
`;

export const BottomRow = styled(Row)`
  position: absolute;
  bottom: 1.875rem;
  left: 1.875rem;
  right: 1.875rem;

  @media (max-width: 975px) {
    display: block;
  }
`;

export const BottomCol = styled(Col)`
  &.delete-button {
    text-align: right;
  }
`;

export const FeaturesWrapper = styled.div`
  display: inline-flex;
  flex-wrap: no-wrap;
  overflow: scroll;
`;

export const FeatureBox = styled.div`
  display: inline;
  border: 0.125rem dashed #e1e1e1;
  padding: 0.625rem 5.625rem;
  margin-right: 1.25rem;
  border-radius: 0.75rem;
  &.active {
    border: 0.125rem dashed #2f8de4;
    background-color: #e5f0f9;
  }
`;

export const GoBackLink = styled(Link)`
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 0.875rem;
  text-decoration: none;
  &:hover {
    color: #2f8de4;
  }
`;

export const SavingIndicator = styled.span`
  margin-right: 1.25rem;
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 0.875rem;
`;

export const ContentRow = styled(Row)`
  display: flex;
  position: relative;
`;

export const ContentCol = styled(Col)`
  display: flex;

  &.captions {
    max-width: 550px;
  }
  &.video {
    display: block;
  }

  @media (max-width: 975px) {
    &.video {
      order: 1;
      display: block;
    }
    &.captions {
      order: 2;
      display: block;
      min-width: 100%;
    }
  }
`;

export const ErrorWrapper = styled.div`
  text-align: center;
  max-width: 350px;
  a {
    color: #2f8de4;
  }
`;

export const Icon = styled.span`
  font-size: 2.625rem;
  display: block;
  &.success {
    color: #5cd394;
  }
  &.error {
    color: #e84969;
  }
`;

export const ExitLoadingButton = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
  background: none;
`;

export const NotificationWrapper = styled.div`
  margin: 1.875rem;
  @media (max-width: 975px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

export const FullMobileWrapper = styled.div`
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
`;
