import DesktopView from "./desktop";
import MobileView from "./mobile";

interface SelectionsProps {
  windowWidth: number;
  mobileView: number;
  currentTime: number;
  videoRef: any;
  clickDelete: any;
  handleTextChange: any;
  captions?: any[];
  formatTime: any;
  setValue: any;
  appearance: any;
  fonts: any;
  register: any;
  updateGoogleFontsLink: any;
  playerHeight?: number;
  fullPageHeight?: number;
  navigationHeight?: number;
}

const Selections = ({
  windowWidth,
  mobileView,
  currentTime,
  videoRef,
  clickDelete,
  handleTextChange,
  captions,
  formatTime,
  setValue,
  appearance,
  fonts,
  register,
  updateGoogleFontsLink,
  playerHeight,
  fullPageHeight,
  navigationHeight,
}: SelectionsProps) => {
  return windowWidth > mobileView ? (
    <DesktopView
      currentTime={currentTime}
      videoRef={videoRef}
      clickDelete={clickDelete}
      handleTextChange={handleTextChange}
      captions={captions}
      formatTime={formatTime}
      setValue={setValue}
      appearance={appearance}
      fonts={fonts}
      register={register}
      updateGoogleFontsLink={updateGoogleFontsLink}
      playerHeight={playerHeight}
    />
  ) : (
    <MobileView
      currentTime={currentTime}
      videoRef={videoRef}
      clickDelete={clickDelete}
      handleTextChange={handleTextChange}
      captions={captions}
      formatTime={formatTime}
      setValue={setValue}
      appearance={appearance}
      fonts={fonts}
      register={register}
      updateGoogleFontsLink={updateGoogleFontsLink}
      playerHeight={playerHeight}
      fullPageHeight={fullPageHeight}
      navigationHeight={navigationHeight}
    />
  );
};

export default Selections;
