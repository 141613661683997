import { useCallback, useState } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import short from "short-uuid";
import { useDispatch, useSelector } from "react-redux";
import { setTranscriptUploading } from "../redux/actions/transcripts";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  UploadMetadata,
} from "firebase/storage";
import { sendErrorNotification } from "../library/notification";
import { IState } from "../types";

const useCreateTranscript = (afterSave: any) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const auth = getAuth(firebaseApp);
  const storage = getStorage(firebaseApp);

  const uploadTranscript = useCallback(
    async (details: any) => {
      try {
        dispatch(setTranscriptUploading(true));
        const token = await auth.currentUser?.getIdToken(true);
        if (token) {
          const request = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/transcription/start`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                token: token,
                workspaceId: selectedWorkspace.id,
                language: details.language,
                format: details.format,
                filename: details.file.name,
                public: details.public,
              }),
            }
          );
          const response = await request.json();
          if (response.error === null && response.result) {
            const id = short.generate();
            const storageRef = ref(
              storage,
              `workspaces/${selectedWorkspace.id}/transcripts/${id}/${details.file.name}`
            );
            const metadata: UploadMetadata = {
              customMetadata: {
                pathId: response.result,
                inProgress: "true",
                workspaceId: selectedWorkspace.id,
                language: details.language,
                format: details.format,
                token: token,
              },
            };
            const uploadFile = uploadBytesResumable(
              storageRef,
              details.file,
              metadata
            );

            uploadFile.on(
              "state_changed",
              (snapshot) => {
                const progressCount =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progressCount);
              },
              (error) => {
                throw error;
              },
              () => {
                setProgress(0);
                dispatch(setTranscriptUploading(false));
                afterSave();
                return "success";
              }
            );
          } else {
            setProgress(0);
            dispatch(setTranscriptUploading(false));
            switch (response.error) {
              case "upgrade-needed":
                sendErrorNotification(
                  "Du har nådd grensen på 2 gratis transkriberinger i måneden."
                );
                break;
              default:
                sendErrorNotification("Kunne ikke starte transkribering.");
            }
          }
        }
      } catch (e) {
        setProgress(0);
        dispatch(setTranscriptUploading(false));
        throw e;
      }
    },
    [afterSave, auth.currentUser, dispatch, storage, selectedWorkspace.id]
  );

  return { uploadTranscript, progress };
};

export default useCreateTranscript;
