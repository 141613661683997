import { getIcon } from "../../utils/get-icon";
import { BackButton, Wrapper } from "./styled";

type BreadcrumbProps = {
  title: string;
  onClick: () => void;
};

const Breadcrumb = ({ title, onClick }: BreadcrumbProps) => {
  return (
    <Wrapper>
      <BackButton onClick={onClick}>
        {getIcon("back")} {title}
      </BackButton>
    </Wrapper>
  );
};

export default Breadcrumb;
