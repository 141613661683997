export const OPTIONS = [
  {
    id: "single",
    title:
      "dashboard.pages.transcripts.add-modal.content.format-step.single-line.title",
    description:
      "dashboard.pages.transcripts.add-modal.content.format-step.single-line.description",
    value: "single",
    icon: "1",
  },
  {
    id: "double",
    title:
      "dashboard.pages.transcripts.add-modal.content.format-step.double-line.title",
    description:
      "dashboard.pages.transcripts.add-modal.content.format-step.double-line.description",
    value: "double",
    icon: "2",
  },
];
