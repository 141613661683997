import { useCallback } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setUserOnboarded } from "../redux/actions/user";

const useOnboarding = () => {
  const auth = getAuth(firebaseApp);
  const dispatch = useDispatch();

  const setUserOnboard = useCallback(async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/user/update/onboard`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: token }),
        }
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(setUserOnboarded());
        return { error: null };
      } else {
        throw response.error;
      }
    } catch (e) {
      throw e;
    }
  }, [auth?.currentUser, dispatch]);

  return { setUserOnboard };
};

export default useOnboarding;
