import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCJMKmQIHl4iLD1ULGxmD95omLQOWBSA40",
  authDomain: "project-x-bc8e6.firebaseapp.com",
  projectId: "project-x-bc8e6",
  storageBucket: "project-x-bc8e6.appspot.com",
  messagingSenderId: "161165899252",
  appId: "1:161165899252:web:38a0278e0e2f1ff5a5f5a1",
  measurementId: "G-TCMK394QVS",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(firebaseApp);
