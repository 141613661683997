import { useEffect, useRef } from "react";
import Caption from "./caption";
import { TimelineContainer } from "./styled";

type TimelineProps = {
  currentTime: number;
  videoRef: any;
  onDelete: any;
  onTextChange: any;
  captions?: any[];
  formatTime: any;
  setValue: any;
  playerHeight?: number;
};

export const Timeline = ({
  currentTime,
  videoRef,
  onDelete,
  onTextChange,
  captions,
  formatTime,
  setValue,
  playerHeight,
}: TimelineProps) => {
  const activeCaptionRef = useRef<any>(null);
  const containerRef = useRef<any>(null);

  const clickSeek = (start: number) => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = start;
  };

  useEffect(() => {
    if (activeCaptionRef.current && containerRef.current) {
      const activeElement = activeCaptionRef.current;
      const container = containerRef.current;

      const activeElementRect = activeElement.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      const offset = (containerRect.height - activeElementRect.height) / 2;

      const topPosition =
        activeElement.offsetTop - container.offsetTop - offset;

      container.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, [currentTime, activeCaptionRef]);

  return (
    <TimelineContainer playerHeight={playerHeight} ref={containerRef}>
      {captions?.map((caption) => (
        <Caption
          key={caption?.key}
          captions={captions}
          caption={caption}
          onDelete={onDelete}
          onTextChange={onTextChange}
          currentTime={currentTime}
          clickSeek={clickSeek}
          ref={activeCaptionRef}
          formatTime={formatTime}
          setValue={setValue}
        />
      ))}
    </TimelineContainer>
  );
};

export default Timeline;
