import ListItem from "./list-item";
import {
  TableContainer,
  Table as FullTable,
  TableRow,
  TableHeader,
  Title,
} from "./styled";
import { useTranslation } from "react-i18next";
import { Transcription } from "../../../../../types";
import EmptyView from "../../../../../components/empty-view";

interface IListViewProps {
  data: Transcription[];
  navigationHeight: number;
}

const ListView: React.FC<IListViewProps> = ({ data, navigationHeight }) => {
  const { t } = useTranslation();

  const TITLES = [
    t("dashboard.pages.transcripts.table.titles.select"),
    t("dashboard.pages.transcripts.table.titles.title"),
    t("dashboard.pages.transcripts.table.titles.date"),
    "Status",
    t("dashboard.pages.transcripts.table.titles.action"),
  ];

  if (data?.length === 0) {
    return (
      <EmptyView
        title={t("dashboard.pages.transcripts.empty-view.title")}
        description={t("dashboard.pages.transcripts.empty-view.text")}
      />
    );
  }

  return (
    <TableContainer>
      <FullTable>
        <TableRow className="header">
          {TITLES.map((title, index, arr) => (
            <TableHeader className={index === arr?.length - 1 ? "select" : ""}>
              <Title>{title}</Title>
            </TableHeader>
          ))}
        </TableRow>
        {data?.map((data, index) => (
          <ListItem
            data={data}
            arr={data}
            index={index}
            navigationHeight={navigationHeight}
          />
        ))}
      </FullTable>
    </TableContainer>
  );
};

export default ListView;
