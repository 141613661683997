import moment from "moment";
import { TableData, TableRow as FullTableRow, Value, Badge } from "./styled";
import { useSelector } from "react-redux";
import Button from "../../../../../../../library/button";
import useDeleteMember from "../../../../../../../hooks/useDeleteMember";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../../../library/notification";
import useMembersList from "../../../../../../../hooks/useMembersList";
import useModal from "../../../../../../../hooks/useModal";
import useWorkspaceDetails from "../../../../../../../hooks/useWorkspaceDetails";
import { useTranslation } from "react-i18next";
import useDeleteInvite from "../../../../../../../hooks/useDeleteInvite";
import { IState } from "../../../../../../../types";

interface ListItemProps {
  data: any;
}

const ListItem: React.FC<ListItemProps> = ({ data }) => {
  const { t } = useTranslation();
  const {
    openModal: openDeleteMemberModal,
    closeModal: closeDeleteMemberModal,
    Confirm: DeleteMemberModal,
  } = useModal();
  const {
    openModal: openDeleteInviteModal,
    closeModal: closeDeleteInviteModal,
    Confirm: DeleteInviteModal,
  } = useModal();
  const { deleteMember } = useDeleteMember();
  const { deleteInvite } = useDeleteInvite();
  const { fetchMembersList } = useMembersList();
  const { fetchWorkspaceDetails } = useWorkspaceDetails();
  const userId = useSelector((state: IState) => state.user?.details?.uid);

  const clickDeleteMember = async () => {
    try {
      const deletion = await deleteMember(data.id);
      if (deletion.error === null) {
        closeDeleteMemberModal();
        sendSuccessNotification(
          t("dashboard.universal.notifications.workspace.delete-user.success")
        );
        fetchMembersList();
        fetchWorkspaceDetails();
      } else {
        closeDeleteMemberModal();
        sendErrorNotification(
          t("dashboard.universal.notifications.workspace.delete-user.failed")
        );
      }
    } catch (e) {
      closeDeleteMemberModal();
      sendErrorNotification(
        t("dashboard.universal.notifications.workspace.delete-user.failed")
      );
    }
  };

  const clickDeleteInvitation = async () => {
    try {
      const deletion = await deleteInvite(data.id);
      if (deletion.error === null) {
        closeDeleteInviteModal();
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.workspace.delete-invitation.success"
          )
        );
        fetchMembersList();
        fetchWorkspaceDetails();
      } else {
        closeDeleteInviteModal();
        sendErrorNotification(
          t(
            "dashboard.universal.notifications.workspace.delete-invitation.failed"
          )
        );
      }
    } catch (e) {
      closeDeleteInviteModal();
      sendErrorNotification(
        t(
          "dashboard.universal.notifications.workspace.delete-invitation.failed"
        )
      );
    }
  };

  const renderName = () => {
    if (data.recipient) {
      return "-";
    } else {
      return `${data.firstName} ${data.lastName}`;
    }
  };

  const checkMember = () => {
    if (data?.recipient) {
      return false;
    } else {
      return true;
    }
  };

  const clickDelete = () => {
    if (data?.recipient) {
      openDeleteInviteModal();
    } else {
      openDeleteMemberModal();
    }
  };

  return (
    <FullTableRow>
      <TableData>
        <Value className="bold">{renderName()}</Value>
      </TableData>
      <TableData>
        <Value>{data.email}</Value>
      </TableData>
      <TableData>
        <Value>{data.role ? data.role : "-"}</Value>
      </TableData>
      <TableData>
        <Badge className={checkMember() ? "active" : "pending"}>
          {checkMember()
            ? t(
                "dashboard.pages.workspace.bottom-view.table.data.statuses.active"
              )
            : t(
                "dashboard.pages.workspace.bottom-view.table.data.statuses.pending"
              )}
        </Badge>
      </TableData>
      <TableData>
        <Value>{moment.unix(data.timestamp).format("HH:mm DD/MM/YYYY")}</Value>
      </TableData>
      <TableData>
        <Button
          color="grey"
          onClick={clickDelete}
          disabled={data.uid === userId}
          size="sm"
          variant="regular"
          ariaLabel={
            t(
              "dashboard.pages.workspace.bottom-view.table.data.buttons.delete.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.workspace.bottom-view.table.data.buttons.delete.title"
          )}
        </Button>
      </TableData>
      <DeleteMemberModal
        onSubmit={clickDeleteMember}
        title={t("dashboard.pages.workspace.delete-user-modal.title")}
        text={t("dashboard.pages.workspace.delete-user-modal.text")!}
        submitText={
          t("dashboard.pages.workspace.delete-user-modal.buttons.delete")!
        }
      />
      <DeleteInviteModal
        onSubmit={clickDeleteInvitation}
        title={t("dashboard.pages.workspace.delete-invite-modal.title")}
        text={t("dashboard.pages.workspace.delete-invite-modal.text")!}
        submitText={
          t("dashboard.pages.workspace.delete-invite-modal.buttons.delete")!
        }
      />
    </FullTableRow>
  );
};

export default ListItem;
