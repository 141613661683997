import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import Button from "../../../../library/button";
import Input from "../../../../library/input";
import { Label } from "../../../../library/label";
import { Select } from "../../../../library/select";
import TextEditor from "../../../../library/text-editor";
import Textarea from "../../../../library/textarea";
import {
  BlogThumbnail,
  ChangePrefixButton,
  Field,
  FileUploadButton,
  PrefixDisplay,
  PrefixInput,
  PrefixLabel,
  ThumbnailOverlay,
  ThumbnailWrapper,
} from "./styled";
import { AddBlogpostSchema } from "./schema";
import { ChangeEvent } from "react";

const BLOGPOST_STATUSES = [
  { value: "draft", title: "Utkast" },
  { value: "published", title: "Publisert" },
];

export const FIELDS = [
  {
    renderField: (
      errors: FieldErrors,
      register: UseFormRegister<AddBlogpostSchema>,
      setValue: UseFormSetValue<AddBlogpostSchema>,
      details: any
    ) => (
      <Field>
        <ThumbnailWrapper>
          {!details?.blogpostThumbnail && <ThumbnailOverlay />}
          <FileUploadButton
            type="file"
            onChange={(f) => details?.handleFileDrop(f, "click")}
            ref={details?.fileInput}
          />
          <Button color="grey" onClick={details?.clickUpload} variant="regular">
            {details?.renderUploadButton()}
          </Button>
          <BlogThumbnail src={details?.renderThumbnail()} />
        </ThumbnailWrapper>
      </Field>
    ),
  },
  {
    renderField: (
      errors: FieldErrors,
      register: UseFormRegister<AddBlogpostSchema>,
      setValue: UseFormSetValue<AddBlogpostSchema>,
      details: any
    ) => (
      <Field>
        <Label>Tittel</Label>
        <Input
          register={register}
          name="title"
          error={errors["title"]}
          variant="regular"
          onBlur={details?.createPrefix}
        />
        <PrefixDisplay>
          https://randi.ai/article/
          {details?.isEditingPrefix ? (
            <PrefixInput
              ref={details?.prefixRef}
              className={details?.isEditingPrefix ? "active" : ""}
              value={details?.blogpostPrefix}
              onChange={details?.onChangePrefix}
            />
          ) : (
            <PrefixLabel>{details?.blogpostPrefix}</PrefixLabel>
          )}
          <ChangePrefixButton type="button" onClick={details?.clickEditPrefix}>
            {details?.isEditingPrefix ? "Ferdig" : "Endre"}
          </ChangePrefixButton>
        </PrefixDisplay>
      </Field>
    ),
  },
  {
    renderField: (
      errors: FieldErrors,
      register: UseFormRegister<AddBlogpostSchema>,
      setValue: UseFormSetValue<AddBlogpostSchema>,
      details: any
    ) => (
      <Field className="bottom-space">
        <Label>Velg status</Label>
        <Select
          items={BLOGPOST_STATUSES}
          onChange={(v: ChangeEvent<HTMLSelectElement>) =>
            setValue("status", v.target.value)
          }
          selected={details?.blogpostStatus}
        />
      </Field>
    ),
  },
  {
    renderField: (
      errors: FieldErrors,
      register: UseFormRegister<AddBlogpostSchema>,
      setValue: UseFormSetValue<AddBlogpostSchema>,
      details: any
    ) => (
      <Field className="bottom-space">
        <Label>Utdrag</Label>
        <Textarea
          rows={2}
          name="excerpt"
          register={register}
          error={errors["excerpt"]}
        />
      </Field>
    ),
  },
  {
    renderField: (
      errors: FieldErrors,
      register: UseFormRegister<AddBlogpostSchema>,
      setValue: UseFormSetValue<AddBlogpostSchema>,
      details: any
    ) => (
      <Field>
        <TextEditor
          onChange={(value: any) => setValue("content", value)}
          value={details?.blogpostContent ?? ""}
          error={errors["content"]}
        />
      </Field>
    ),
  },
];
