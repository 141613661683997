import {
  InnerNavigationWrapper,
  NavbarBrand,
  NavbarCollapse,
  NavbarLogo,
  NavbarToggle,
  NavbarWrapper,
  NavigationWrapper,
  Toggle,
} from "./styled";
import useUser from "../../hooks/useWorkspaces";
import { firebaseApp } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { sendErrorNotification } from "../../library/notification";
import flagNorway from "../../assets/img/flag-norway.svg";
import flagEnglish from "../../assets/img/flag-english.svg";
import flagSweden from "../../assets/img/flag-sweden.svg";
import flagDenmark from "../../assets/img/flag-denmark.svg";
import { getAuth } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearUserState } from "../../redux/actions/user";
import i18next from "i18next";
import logoLight from "../../assets/img/logo-single.svg";
import { getIcon } from "../../utils/get-icon";
import { useEffect, useState } from "react";
import { IState } from "../../types";
import OnboardingModalContent from "../../components/onboarding-modal";
import Modal from "../../library/modal/new-modal";
import DesktopView from "./desktop-view";
import MobileView from "./mobile-view";

const MOBILE_VIEW = 975;

interface DashboardUpperNavigationProps {
  navigationRef?: any;
}

const DashboardUpperNavigation = ({
  navigationRef,
}: DashboardUpperNavigationProps) => {
  const [onboardingOpen, setOnboardingOpen] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const auth = getAuth(firebaseApp);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const details = useSelector((state: IState) => state.user.details);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const { loading } = useUser();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clickSignout = async () => {
    try {
      await auth.signOut();
      navigate("/login");
      dispatch(clearUserState());
    } catch (e) {
      sendErrorNotification("Kunne ikke logge ut.");
    }
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const closeOnboardingModal = () => {
    setOnboardingOpen(false);
  };

  const openOnboardingModal = () => {
    setOnboardingOpen(true);
  };

  const LANGUAGE_SELECTIONS = [
    {
      display: (
        <span
          aria-label={
            t(
              "components.public-navigation.language-dropdown.selections.danish"
            ) as string
          }
          className="single-selection"
        >
          <img
            alt={
              t("components.public-navigation.language-dropdown.flags.danish")!
            }
            src={flagDenmark}
          />{" "}
          {t(
            "components.public-navigation.language-dropdown.language-options.danish"
          )}
        </span>
      ),
      onClick: () => changeLanguage("dk"),
    },
    {
      display: (
        <span
          aria-label={
            t(
              "components.public-navigation.language-dropdown.selections.english"
            ) as string
          }
          className="single-selection"
        >
          <img
            alt={
              t("components.public-navigation.language-dropdown.flags.english")!
            }
            src={flagEnglish}
          />{" "}
          {t(
            "components.public-navigation.language-dropdown.language-options.english"
          )}
        </span>
      ),
      onClick: () => changeLanguage("en"),
    },
    {
      display: (
        <span
          aria-label={
            t(
              "components.public-navigation.language-dropdown.selections.norwegian"
            ) as string
          }
          className="single-selection"
        >
          <img
            alt={
              t(
                "components.public-navigation.language-dropdown.flags.norwegian"
              )!
            }
            src={flagNorway}
          />{" "}
          {t(
            "components.public-navigation.language-dropdown.language-options.norwegian"
          )}
        </span>
      ),
      onClick: () => changeLanguage("no"),
    },
    {
      display: (
        <span
          aria-label={
            t(
              "components.public-navigation.language-dropdown.selections.swedish"
            ) as string
          }
          className="single-selection"
        >
          <img
            alt={
              t("components.public-navigation.language-dropdown.flags.swedish")!
            }
            src={flagSweden}
          />{" "}
          {t(
            "components.public-navigation.language-dropdown.language-options.swedish"
          )}
        </span>
      ),
      onClick: () => changeLanguage("sv"),
    },
  ];

  const ACCOUNT_SELECTIONS = [
    {
      display: (
        <span
          aria-label={
            t(
              "dashboard.top-navigation.user-dropdown.links.account-settings.ariaLabel"
            ) as string
          }
          className="single-selection"
        >
          {t(
            "dashboard.top-navigation.user-dropdown.links.account-settings.title"
          )}
        </span>
      ),
      onClick: () => navigate("/dashboard/account"),
    },
    {
      display: (
        <span
          aria-label={
            t(
              "dashboard.top-navigation.user-dropdown.links.sign-out.ariaLabel"
            ) as string
          }
          className="single-selection"
        >
          {t("dashboard.top-navigation.user-dropdown.links.sign-out.title")}
        </span>
      ),
      onClick: () => clickSignout(),
    },
  ];

  const renderLanguage = () => {
    const language = i18next.language;
    switch (language) {
      case "no":
        return (
          <span className="language-wrapper">
            <img
              src={flagNorway}
              alt={
                t(
                  "components.public-navigation.language-dropdown.selected-flag.norwegian"
                ) as string
              }
            />
          </span>
        );
      case "sv":
        return (
          <span className="language-wrapper">
            <img
              src={flagSweden}
              alt={
                t(
                  "components.public-navigation.language-dropdown.selected-flag.sweden"
                ) as string
              }
            />
          </span>
        );
      case "dk":
        return (
          <span className="language-wrapper">
            <img
              src={flagDenmark}
              alt={
                t(
                  "components.public-navigation.language-dropdown.selected-flag.denmark"
                ) as string
              }
            />
          </span>
        );
      default:
        return (
          <span className="language-wrapper">
            <img
              src={flagEnglish}
              alt={
                t(
                  "components.public-navigation.language-dropdown.selected-flag.english"
                ) as string
              }
            />
          </span>
        );
    }
  };

  const checkActive = (path: string) => {
    return location.pathname.includes(path);
  };

  return (
    <NavigationWrapper ref={navigationRef}>
      <InnerNavigationWrapper>
        <NavbarWrapper
          aria-label={t("dashboard.top-navigation.ariaLabel") as string}
          collapseOnSelect
          expand="lg"
        >
          <NavbarBrand href="/">
            <NavbarLogo alt="Randi logo" src={logoLight} />
          </NavbarBrand>
          <NavbarToggle aria-controls="responsive-navbar-nav">
            <Toggle onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? getIcon("exit") : getIcon("menu")}
            </Toggle>
          </NavbarToggle>
          <NavbarCollapse id="responsive-navbar-nav">
            {windowWidth > MOBILE_VIEW ? (
              <DesktopView
                checkActive={checkActive}
                selectedWorkspace={selectedWorkspace}
                navigationItems={ACCOUNT_SELECTIONS}
                languageItems={LANGUAGE_SELECTIONS}
                loading={loading}
                details={details}
                renderLanguage={renderLanguage}
                openOnboardingModal={openOnboardingModal}
              />
            ) : (
              <MobileView
                loading={loading}
                details={details}
                checkActive={checkActive}
                renderLanguage={renderLanguage}
                selectedWorkspace={selectedWorkspace}
                languageItems={LANGUAGE_SELECTIONS}
                onSignout={clickSignout}
              />
            )}
          </NavbarCollapse>
        </NavbarWrapper>
      </InnerNavigationWrapper>
      <Modal
        open={onboardingOpen}
        onClose={closeOnboardingModal}
        title="Velkommen til Randi!"
        size={1100}
      >
        <OnboardingModalContent onClose={closeOnboardingModal} />
      </Modal>
    </NavigationWrapper>
  );
};

export default DashboardUpperNavigation;
