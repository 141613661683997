import { useDispatch, useSelector } from "react-redux";
import { IState } from "../types";
import { useCallback } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { setBlogposts, setBlogpostsLoading } from "../redux/actions/blogposts";

const useFetchAdminBlogposts = () => {
  const dispatch = useDispatch();
  const auth = getAuth(firebaseApp);
  const loading = useSelector((state: IState) => state.blogposts.loadingList);

  const fetchBlogpostsList = useCallback(async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true);

      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/admin/blogposts?token=${token}`,
        {
          method: "GET",
        }
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(setBlogpostsLoading(false));
        dispatch(setBlogposts(response.status));
      } else {
        throw response.error;
      }
    } catch (e) {
      dispatch(setBlogpostsLoading(false));
      throw e;
    }
  }, [dispatch, auth.currentUser]);

  return { loading, fetchBlogpostsList };
};

export default useFetchAdminBlogposts;
