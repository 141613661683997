import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSoundtrackStatus } from "../redux/actions/soundtracks";
import { IState } from "../types";

const useMonitorSoundtrack = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState<number>(0);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const monitorSoundtrack = async (soundtrackId: string, token: string) => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/text-to-speech/status?workspaceId=${selectedWorkspace.id}&soundtrackId=${soundtrackId}&token=${token}`,
        { method: "GET" }
      );
      const response = await request.json();
      if (response.error === null) {
        setProgress(response.status.progress);
        if (response.status.progress === 100) {
          dispatch(
            setSoundtrackStatus({
              id: soundtrackId,
              status: "completed",
              downloadUrl: response.status.downloadUrl,
            })
          );
        }
      } else {
        throw response.error;
      }
    } catch (e) {
      throw e;
    }
  };
  return { monitorSoundtrack, progress, setProgress };
};

export default useMonitorSoundtrack;
