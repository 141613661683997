import * as type from "../types/article";

export const setArticle = (payload: any) => {
  return {
    type: type.SET_ARTICLE,
    article: payload,
  };
};

export const clearArticle = () => {
  return {
    type: type.CLEAR_ARTICLE,
  };
};

export const setArticleLoading = (payload: any) => {
  return {
    type: type.SET_ARTICLE_LOADING,
    loading: payload,
  };
};
