import moment from "moment";
import {
  TableData,
  TableRow as FullTableRow,
  Value,
  ButtonsWrapper,
} from "./styled";
import Checkbox from "../../../../../../library/checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  appendDeletionArray,
  subtractDeletionArray,
} from "../../../../../../redux/actions/blogposts";
import Button from "../../../../../../library/button";
import useModal from "../../../../../../hooks/useModal";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../../library/notification";
import useDeleteBlogpost from "../../../../../../hooks/useDeleteBlogpost";
import useFetchBlogpostsList from "../../../../../../hooks/useFetchAdminBlogposts";
import { IState, Transcription } from "../../../../../../types";
import { Link } from "react-router-dom";

interface ITableRowProps {
  data: Transcription;
  arr: any;
  index: number;
}

const TableRow: React.FC<ITableRowProps> = ({ data }) => {
  const dispatch = useDispatch();

  const { deleteBlogpost } = useDeleteBlogpost();
  const { fetchBlogpostsList } = useFetchBlogpostsList();
  const {
    openModal: openDeleteModal,
    Confirm: DeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const deletions = useSelector(
    (state: IState) => state.transcripts.deletionArray
  );

  const handleSingleSelection = (e: any, id: string) => {
    if (e.target.checked) {
      if (!deletions.includes(id)) {
        dispatch(appendDeletionArray(id));
      }
    } else {
      if (deletions.includes(id)) {
        dispatch(subtractDeletionArray(id));
      }
    }
  };

  const clickDeleteBlogpost = async () => {
    try {
      const deletion = await deleteBlogpost(data.id);
      if (deletion.error === null) {
        sendSuccessNotification("Innlegget ble slettet.");
        closeDeleteModal();
        fetchBlogpostsList();
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke slette innlegg.");
    }
  };

  return (
    <FullTableRow>
      <TableData className="select">
        <Checkbox
          onChange={(e: any) => handleSingleSelection(e, data.id)}
          checked={deletions.includes(data.id) ? true : false}
          name="product-select"
        />
      </TableData>
      <TableData>
        <Value className="bold title">
          {data.title ? (
            <Link to={`/dashboard/admin/blogposts/${data.id}`}>
              {data.title}
            </Link>
          ) : (
            "Ikke tilgjengelig"
          )}
        </Value>
      </TableData>
      <TableData className="date-display">
        <Value>{moment.unix(data.timestamp).format("HH:mm DD/MM/YYYY")}</Value>
      </TableData>
      <TableData className="buttons">
        <ButtonsWrapper>
          <Button
            spacing="left"
            size="md"
            color="grey"
            variant="icon"
            icon="trash"
            onClick={openDeleteModal}
          />
        </ButtonsWrapper>
      </TableData>
      <DeleteModal
        title="Slette innlegg?"
        text="Dersom du sletter dette innlegget vil du ikke ha mulighet til å gjenopprette det."
        onSubmit={clickDeleteBlogpost}
        submitText="Ja, slett det"
      />
    </FullTableRow>
  );
};

export default TableRow;
