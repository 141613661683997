import { useCallback, useState } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { EditBlogpostSchema } from "../pages/dashboard/edit-blogpost/content/schema";

const useUpdateBlogpost = () => {
  const auth = getAuth(firebaseApp);
  const [loading, setLoading] = useState<boolean>(false);
  const storage = getStorage(firebaseApp);

  const updateBlogpost = useCallback(
    async (data: EditBlogpostSchema) => {
      try {
        setLoading(true);
        const token = await auth.currentUser?.getIdToken(true);
        if (token && data?.id) {
          let thumbnail = data?.thumbnail;
          if (typeof thumbnail === "object") {
            let split = thumbnail.name.split(".");
            let extension = split[split.length - 1];
            const storageRef = ref(
              storage,
              `blogposts/${data.id}/thumbnail.${extension}`
            );

            await uploadBytesResumable(storageRef, data.thumbnail);

            let url = await getDownloadURL(storageRef);
            data = { ...data, thumbnail: url };

            const request = await fetch(
              `${process.env.REACT_APP_BACKEND_ENDPOINT}/admin/blogpost/update`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  token: token,
                  data: data,
                }),
              }
            );
            const response = await request.json();
            if (response.error === null) {
              return { error: null };
            } else {
              throw response.error;
            }
          } else {
            const request = await fetch(
              `${process.env.REACT_APP_BACKEND_ENDPOINT}/admin/blogpost/update`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  token: token,
                  data: data,
                }),
              }
            );
            const response = await request.json();
            if (response.error === null) {
              return { error: null };
            } else {
              throw response.error;
            }
          }
        }
      } catch (e) {
        setLoading(false);
      }
    },
    [auth.currentUser, storage]
  );

  return { loading, updateBlogpost };
};

export default useUpdateBlogpost;
