import { UploadForm } from "../styled";
import { useState } from "react";
import { sendErrorNotification } from "../../../../../library/notification";
import useUploadTranscript from "../../../../../hooks/useUploadTranscript";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UploadTranscriptSchema, uploadTranscriptSchema } from "./schema";
import SelectTypeView from "./type-view";
import StepBar from "../../../../../library/step-bar";
import { ContentWrapper } from "./styled";
import SelectLanguageView from "./language-view";
import SourceView from "./source-view";
import FormatView from "./format-view";

interface IAddModalProps {
  closeModal: () => void;
}

const AddModal: React.FC<IAddModalProps> = ({ closeModal }) => {
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const { t } = useTranslation();
  const { uploadTranscript, progress } = useUploadTranscript(closeModal);

  const [selectionType, setSelectionType] = useState<
    "file" | "recording" | null
  >("file");

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UploadTranscriptSchema>({
    resolver: zodResolver(uploadTranscriptSchema),
    defaultValues: {
      file: undefined,
      language: "no",
      public: false,
      format: "single",
    },
  });

  const selectFormat = (value: string) => {
    setValue("format", value);
  };

  const selectedFile = watch("file");
  const selectedLanguage = watch("language");
  const selectedFormat = watch("format");
  const isFilePublic = watch("public");

  const performUploadTranscript = async (data: UploadTranscriptSchema) => {
    try {
      await uploadTranscript(data);
    } catch (e) {
      sendErrorNotification(
        t("dashboard.pages.transcripts.notifications.upload-limit")
      );
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <SelectTypeView
            setStep={setStep}
            setSelectionType={setSelectionType}
            selectionType={selectionType}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <SelectLanguageView
            setStep={setStep}
            setValue={setValue}
            selectedLanguage={selectedLanguage}
          />
        );
      case 3:
        return (
          <FormatView
            setStep={setStep}
            setValue={setValue}
            selectedFormat={selectedFormat}
            setSelectionType={selectFormat}
          />
        );
      default:
        return (
          <SourceView
            selectionType={selectionType}
            setValue={setValue}
            selectedFile={selectedFile}
            isFilePublic={isFilePublic}
            progress={progress}
            setStep={setStep}
            errors={errors}
          />
        );
    }
  };

  return (
    <UploadForm onSubmit={handleSubmit(performUploadTranscript)}>
      <StepBar
        currentStep={step}
        titles={[
          t("dashboard.pages.transcripts.add-modal.progress-bar.select-type"),
          t(
            "dashboard.pages.transcripts.add-modal.progress-bar.select-language"
          ),
          t("dashboard.pages.transcripts.add-modal.progress-bar.select-format"),
          t("dashboard.pages.transcripts.add-modal.progress-bar.select-source"),
        ]}
      />
      <ContentWrapper>{renderStep()}</ContentWrapper>
    </UploadForm>
  );
};

export default AddModal;
