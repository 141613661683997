import { useCallback, useEffect, useRef, useState } from "react";
import { Title } from "../../../../library/title";
import {
  PageContainer,
  ContentWrapper,
  ContentRow,
  ContentCol,
  LoaderWrapper,
  ErrorWrapper,
  Icon,
  NotificationWrapper,
  FullMobileWrapper,
} from "./styled";
import { Link, useParams } from "react-router-dom";
import useTranscript from "../../../../hooks/useTranscript";
import { useSelector } from "react-redux";
import { IState } from "../../../../types";
import VideoPlayer from "./video-player";
import Slider from "./slider";
import short from "short-uuid";
import Meta from "./meta";
import { sendErrorNotification } from "../../../../library/notification";
import SubscriptionModalContent from "../../../../components/subscription-modal";
import { getIcon } from "../../../../utils/get-icon";
import { useNavigate } from "react-router-dom";
import TableLoader from "../../../../library/table-loader";
import { Text } from "../../../../library/text";
import useGenerateVideo from "../../../../hooks/useGenerateVideo";
import useMonitorVideoGeneration from "../../../../hooks/useMonitorVideoGeneration";
import AttentionBox from "../../../../library/attention-box";
import { useTranslation } from "react-i18next";
import useFetchVideoEditorFonts from "../../../../hooks/useFetchVideoEditorFonts";
import { useForm } from "react-hook-form";
import { VideoAppearanceSchema, videoAppearanceSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { defaultApperance } from "./utils";
import Modal from "../../../../library/modal/new-modal";
import TopBar from "./top-bar";
import Selections from "./selections";

const MOBILE_VIEW = 975;

interface ContentProps {
  navigationRef: any;
}

const Content = ({ navigationRef }: ContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { downloadVideo } = useGenerateVideo();
  const { monitorVideoGeneration } = useMonitorVideoGeneration();
  const { fetchVideoEditorFonts, fonts } = useFetchVideoEditorFonts();

  const { fetchError, saveLoading, getTranscript, transcript, saveTranscript } =
    useTranscript();

  const [showSubscriptionModal, setShowSubscriptionModal] =
    useState<boolean>(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openSubscriptionModal = () => {
    setShowSubscriptionModal(true);
  };

  const closeSubscriptionModal = () => {
    setShowSubscriptionModal(false);
  };

  const [currentTime, setCurrentTime] = useState(0);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [videoDownloadUrl, setVideoDownloadUrl] = useState<string>();
  const [progress, setProgress] = useState<number>(0);

  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const user = useSelector((state: IState) => state.user.details);
  const videoRef = useRef<HTMLVideoElement>(null);

  const formatTime = useCallback((time: any) => {
    const pad = (number: any, digits: number) => {
      return number.toString().padStart(digits, "0");
    };

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const remainingSeconds = time % 60;
    const milliseconds = Math.round(
      (remainingSeconds - Math.floor(remainingSeconds)) * 1000
    );

    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(
      Math.floor(remainingSeconds),
      2
    )}.${pad(milliseconds, 3)}`;
  }, []);

  function isVideoFile() {
    const videoExtensions = [
      ".mp4",
      ".mpeg",
      ".mpg",
      ".m4v",
      ".webm",
      ".ogg",
      ".heic",
      ".avi",
      ".mov",
    ];
    return videoExtensions.some((extension) =>
      transcript?.filename?.toLowerCase().endsWith(extension)
    );
  }

  const { register, setValue, watch } = useForm<VideoAppearanceSchema>({
    resolver: zodResolver(videoAppearanceSchema),
    defaultValues: {
      captions: [],
      appearance: transcript?.appearance ?? defaultApperance,
    },
  });

  const captions = watch("captions");
  const appearance = watch("appearance");

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid && id && !transcript?.id) {
      getTranscript(id);
      document.documentElement.style.setProperty("--player-volume", `100%`);
      if (!fonts) {
        fetchVideoEditorFonts();
      }
    }
  }, [
    fetchVideoEditorFonts,
    fonts,
    getTranscript,
    id,
    selectedWorkspace?.id,
    transcript?.id,
    user?.uid,
  ]);

  const updateGoogleFontsLink = useCallback(
    (selectedFont: any) => {
      const linkElement = document.getElementById(
        "editor-preview-fonts-link"
      ) as HTMLLinkElement;
      if (linkElement) {
        linkElement.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
          " ",
          "+"
        )}&display=swap`;
      } else {
        const newLink = document.createElement("link");
        newLink.id = "editor-preview-fonts-link";
        newLink.rel = "stylesheet";
        newLink.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
          " ",
          "+"
        )}&display=swap`;
        document.head.appendChild(newLink);
      }
      setValue("appearance.fontFamily", selectedFont.family);
    },
    [setValue]
  );

  useEffect(() => {
    if (transcript?.appearance?.fontFamily) {
      setValue("appearance", transcript?.appearance);
      updateGoogleFontsLink({ family: transcript.appearance.fontFamily });
    }
  }, [setValue, transcript?.appearance, updateGoogleFontsLink]);

  const handleTimeUpdate = () => {
    if (!videoRef.current) return;
    const time = videoRef.current.currentTime;
    setCurrentTime(time);
  };

  const handleSeek = (time: number) => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = time;
  };

  const loadContent = useCallback(() => {
    if (transcript?.content?.length > 0) {
      const fields: any[] = [];
      transcript?.content?.forEach((caption: any) => {
        const key = short.generate();
        fields.push({ ...caption, key: key });
      });
      setValue("captions", fields);
    }
  }, [setValue, transcript?.content]);

  useEffect(() => {
    if (!transcript?.content || (captions && captions.length > 0)) return;
    loadContent();
  }, [captions, loadContent, transcript?.content]);

  const clickDelete = (key: string) => {
    setValue(
      "captions",
      captions.filter((caption: any) => caption.key !== key)
    );
  };

  const handleTextChange = (key: string, value: string) => {
    setValue(
      "captions",
      captions.map((caption: any) => {
        if (caption.key === key) {
          return { ...caption, text: value };
        }
        return caption;
      })
    );
  };

  const onSave = async () => {
    try {
      if (transcript?.id && captions && captions?.length > 0) {
        saveTranscript(captions, appearance, transcript.id);
      } else {
        throw new Error("Error");
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke lagre transkript.");
    }
  };

  const triggerDownload = (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const startPolling = () => {
    const pollInterval = 10000;

    const poll = async () => {
      try {
        const { downloadUrl, progress } = await monitorVideoGeneration(
          transcript?.id
        );

        setProgress(progress);

        if (downloadUrl) {
          setGenerateLoading(false);
          setVideoDownloadUrl(downloadUrl);
          setProgress(0);
          return;
        }

        setTimeout(poll, pollInterval);
      } catch (error) {
        setGenerateLoading(false);
      }
    };

    poll();
  };

  const onGenerateVideo = async () => {
    try {
      if (transcript?.id && isVideoFile()) {
        const download = await downloadVideo(
          transcript.id,
          captions,
          appearance
        );

        if (download?.error === null) {
          const { status, downloadUrl } = download.data;
          if (status === "download") {
            triggerDownload(downloadUrl);
          } else {
            setGenerateLoading(true);
            startPolling();
          }
        }
        setVideoDownloadUrl(undefined);
      } else {
        sendErrorNotification(
          "Filen du har lastet opp er ikke i et video-format."
        );
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke generere video.");
    }
  };

  const clickGoBack = () => {
    navigate("/dashboard/transcripts");
  };

  const renderAttention = () => {
    if (dataLoaded && selectedWorkspace?.subscription === "small") {
      return true;
    }
    return false;
  };

  return (
    <>
      <Meta />
      <PageContainer>
        {!dataLoaded && (
          <LoaderWrapper>
            {showError || fetchError ? (
              <ErrorWrapper>
                <Icon className="error">{getIcon("error")}</Icon>
                <Title>En feil oppstod</Title>
                <Text color="dark">
                  Kunne ikke laste inn video og transkript. Trykk{" "}
                  <Link to="/dashboard/transcripts">her</Link> for å gå tilbake.
                </Text>
              </ErrorWrapper>
            ) : (
              <TableLoader message="Laster editor" />
            )}
          </LoaderWrapper>
        )}
        {false && (
          <NotificationWrapper>
            {false && renderAttention() ? (
              <AttentionBox
                icon="information"
                title="Nå kan du laste ned ferdig tekstet video"
                type="information"
                onClick={openSubscriptionModal}
                buttonText="Oppgrader nå"
                text="Nå kan du laste ned video med transkriptet ditt brent inn som undertekst. Dersom du har den gratis versjonen vil du få et vannmerke øverst i venstre hjørne på hver video du laster ned. Du kan fjerne dette vannmerket ved å oppgradere til Randi Go. Vennligst merk deg at jo større videoen er desto lenger vil forberedelsesprosessen ta."
              />
            ) : (
              <AttentionBox
                icon="information"
                title="Nå kan du laste ned ferdig tekstet video"
                type="information"
                text="Nå kan du laste ned video med transkriptet ditt brent inn som undertekst. Vennligst merk deg at jo større videoen er desto lenger vil forberedelsesprosessen ta."
              />
            )}
          </NotificationWrapper>
        )}
        <TopBar
          onGoBack={clickGoBack}
          transcript={transcript}
          saveLoading={saveLoading}
          generateLoading={generateLoading}
          onSave={onSave}
          onGenerateVideo={onGenerateVideo}
          progress={progress}
          windowWidth={windowWidth}
          mobileView={MOBILE_VIEW}
          captions={captions}
          appearance={appearance}
          videoDownloadUrl={videoDownloadUrl}
          setVideoDownloadUrl={setVideoDownloadUrl}
          navigationHeight={navigationRef.current?.clientHeight}
        />
        {windowWidth > MOBILE_VIEW ? (
          <ContentWrapper>
            <ContentRow>
              <ContentCol className="captions">
                <Selections
                  windowWidth={windowWidth}
                  mobileView={MOBILE_VIEW}
                  currentTime={currentTime}
                  formatTime={formatTime}
                  setValue={setValue}
                  handleTextChange={handleTextChange}
                  clickDelete={clickDelete}
                  fonts={fonts}
                  register={register}
                  appearance={appearance}
                  videoRef={videoRef}
                  updateGoogleFontsLink={updateGoogleFontsLink}
                  captions={captions}
                  playerHeight={videoRef.current?.clientHeight}
                  fullPageHeight={window.innerHeight}
                  navigationHeight={navigationRef.current?.clientHeight}
                />
              </ContentCol>
              <ContentCol className="video">
                <VideoPlayer
                  videoUrl={transcript?.videoUrl}
                  captions={captions}
                  currentTime={currentTime}
                  onTimeUpdate={handleTimeUpdate}
                  videoRef={videoRef}
                  configuration={appearance}
                  mobileView={MOBILE_VIEW}
                  windowWidth={windowWidth}
                  navigationHeight={navigationRef.current?.clientHeight}
                />
              </ContentCol>
            </ContentRow>
          </ContentWrapper>
        ) : (
          <FullMobileWrapper>
            <VideoPlayer
              videoUrl={transcript?.videoUrl}
              captions={captions}
              currentTime={currentTime}
              onTimeUpdate={handleTimeUpdate}
              videoRef={videoRef}
              configuration={appearance}
              mobileView={MOBILE_VIEW}
              windowWidth={windowWidth}
              navigationHeight={navigationRef.current?.clientHeight}
            />
            <Selections
              windowWidth={windowWidth}
              mobileView={MOBILE_VIEW}
              currentTime={currentTime}
              formatTime={formatTime}
              setValue={setValue}
              handleTextChange={handleTextChange}
              clickDelete={clickDelete}
              fonts={fonts}
              register={register}
              appearance={appearance}
              videoRef={videoRef}
              updateGoogleFontsLink={updateGoogleFontsLink}
              captions={captions}
              playerHeight={videoRef.current?.clientHeight}
              fullPageHeight={window.innerHeight}
              navigationHeight={navigationRef.current?.clientHeight}
            />
          </FullMobileWrapper>
        )}

        <Slider
          audioUrl={transcript?.audioUrl}
          audioUrlMobile={transcript?.audioUrlMobile}
          onSeek={handleSeek}
          currentTime={currentTime}
          duration={videoRef?.current?.duration}
          setDataLoaded={setDataLoaded}
          dataLoaded={dataLoaded}
          setShowError={setShowError}
          mobileView={MOBILE_VIEW}
          windowWidth={windowWidth}
        />
        <Modal
          open={showSubscriptionModal}
          onClose={closeSubscriptionModal}
          title={t("dashboard.pages.workspace.change-subscription-modal.title")}
        >
          <SubscriptionModalContent onClose={closeSubscriptionModal} />
        </Modal>
      </PageContainer>
    </>
  );
};

export default Content;
