import { Soundtrack } from "../../types";
import * as type from "../types/soundtracks";

const initialState = {
  records: {
    private: [],
    public: [],
  },
  loadingList: true,
  deletionArray: [],
  searchQuery: null,
  loadingCreation: false,
};

export const soundtracks = (state = initialState, payload: any) => {
  switch (payload.type) {
    case type.SET_SOUNDTRACKS_LIST:
      return {
        ...state,
        records: payload.soundtracks,
      };
    case type.CLEAR_SOUNDTRACKS_LIST:
      return {
        ...state,
        records: [],
      };
    case type.SET_SOUNDTRACKS_LIST_LOADING:
      return {
        ...state,
        loadingList: payload.loading,
      };
    case type.SET_SOUNDTRACK_CREATION_LOADING:
      return {
        ...state,
        loadingCreation: payload.loading,
      };
    case type.SET_SOUNDTRACKS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: payload.query !== "" ? payload.query : null,
      };
    case type.CLEAR_SOUNDTRACKS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: null,
      };
    case type.APPEND_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: [...state.deletionArray, payload.id],
      };
    case type.SUBTRACT_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: state.deletionArray.filter(
          (existing) => existing !== payload.id
        ),
      };
    case type.CLEAR_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: [],
      };
    case type.SET_SOUNDTRACK_STATUS:
      return {
        ...state,
        records: {
          ...state.records,
          public: state.records.public.map((soundtrack: Soundtrack) => {
            if (soundtrack.id === payload.details.id) {
              return {
                ...soundtrack,
                status: payload.details.status,
                downloadUrl: payload.details.downloadUrl,
                progress: payload.details.progress,
              };
            }
            return soundtrack;
          }),
          private: state.records.private.map((soundtrack: Soundtrack) => {
            if (soundtrack.id === payload.details.id) {
              return {
                ...soundtrack,
                status: payload.details.status,
                downloadUrl: payload.details.downloadUrl,
                progress: payload.details.progress,
              };
            }
            return soundtrack;
          }),
        },
      };
    default:
      return state;
  }
};
