import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firebaseApp } from "../firebase";
import { updateWorkspaceSubscription } from "../redux/actions/user";
import { getAuth } from "firebase/auth";
import { IState } from "../types";

const useSubscription = () => {
  const dispatch = useDispatch();
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const auth = getAuth(firebaseApp);

  const updateLicenseQuantity = useCallback(
    async (quantity: string, subscription: string) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        if (token) {
          const request = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/subscription/update`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                workspaceId: selectedWorkspace.id,
                quantity: quantity,
                token: token,
                subscription: subscription,
              }),
            }
          );
          const response = await request.json();
          if (response.error === null) {
            return { error: null };
          }
        }
      } catch (e) {
        return { error: e };
      }
    },
    [auth.currentUser, selectedWorkspace.id]
  );
  const cancelSubscription = useCallback(async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/subscription/cancel/${selectedWorkspace.id}/${token}`,
        { method: "DELETE" }
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(
          updateWorkspaceSubscription({
            status: "canceled",
            id: selectedWorkspace.id,
          })
        );
        return { error: null };
      } else {
        return { error: response.error };
      }
    } catch (e) {
      return { error: e };
    }
  }, [dispatch, auth.currentUser, selectedWorkspace.id]);
  const fetchSubscriptionStatus = useCallback(async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/workpace/subscription/status?workspaceId=${selectedWorkspace.id}&token=${token}`
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(
          updateWorkspaceSubscription({
            status: response.result,
            id: selectedWorkspace.id,
          })
        );
      } else {
        throw response.error;
      }
    } catch (e) {
      throw e;
    }
  }, [dispatch, auth.currentUser, selectedWorkspace.id]);
  return { updateLicenseQuantity, cancelSubscription, fetchSubscriptionStatus };
};

export default useSubscription;
