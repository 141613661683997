import { getIcon } from "../../../../../../utils/get-icon";
import {
  ControllerWrapper,
  BottomControllerWrapper,
  LeftBottomWrapper,
  MainControls,
  SpeedButton,
  CenterBottomWrapper,
  AudioButton,
  VolumeInput,
  ExpandButton,
  RightBottomWrapper,
  SoundControls,
  DurationWrapper,
  Duration,
  PlayButton,
  BeginningButton,
} from "./styled";

interface VideoPlayerDesktopProps {
  clickGoBackward: () => void;
  togglePlayPause: () => void;
  isPlaying: boolean;
  clickGoForward: () => void;
  clickStartBeginning: () => void;
  volume: number;
  handleVolumeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mute: boolean;
  handleMute: () => void;
  clickFullscreen: () => void;
  currentTime: number;
  videoRef: any;
}

const VideoPlayerDesktop: React.FC<VideoPlayerDesktopProps> = ({
  clickGoBackward,
  togglePlayPause,
  isPlaying,
  clickGoForward,
  clickStartBeginning,
  volume,
  handleVolumeChange,
  mute,
  handleMute,
  clickFullscreen,
  currentTime,
  videoRef,
}) => {
  function formatTime(time: number | undefined): string {
    if (time === undefined) return "00:00:00";
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }

  function padZero(num: number): string {
    return num.toString().padStart(2, "0");
  }

  return (
    <ControllerWrapper>
      <BottomControllerWrapper>
        <LeftBottomWrapper>
          <MainControls>
            <SpeedButton onClick={clickGoBackward}>
              {getIcon("backward")}
            </SpeedButton>
            <PlayButton onClick={togglePlayPause}>
              {isPlaying ? getIcon("pause") : getIcon("play")}
            </PlayButton>
            <SpeedButton onClick={clickGoForward}>
              {getIcon("forward")}
            </SpeedButton>
            <BeginningButton onClick={clickStartBeginning}>
              {getIcon("start-beginning")}
            </BeginningButton>
          </MainControls>
        </LeftBottomWrapper>
        <CenterBottomWrapper className="my-auto">
          <DurationWrapper>
            <Duration>
              {formatTime(currentTime)}/{formatTime(videoRef.current?.duration)}
            </Duration>
          </DurationWrapper>
        </CenterBottomWrapper>
        <RightBottomWrapper>
          <SoundControls>
            <VolumeInput
              type="range"
              min={0}
              max={1}
              step={0.1}
              value={volume}
              onChange={handleVolumeChange}
            />
            <AudioButton onClick={handleMute}>
              {mute ? getIcon("volume-off") : getIcon("volume-on")}
            </AudioButton>
          </SoundControls>
          <ExpandButton onClick={clickFullscreen}>
            {getIcon("expand")}
          </ExpandButton>
        </RightBottomWrapper>
      </BottomControllerWrapper>
    </ControllerWrapper>
  );
};

export default VideoPlayerDesktop;
