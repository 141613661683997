import { Transcription } from "../../types";
import * as type from "../types/transcripts";

const initialState = {
  records: {
    private: [],
    public: [],
  },
  loadingList: true,
  deletionArray: [],
  searchQuery: null,
  loadingUpload: false,
};

export const transcripts = (state = initialState, payload: any) => {
  switch (payload.type) {
    case type.SET_TRANSCRIPTS_LIST:
      return {
        ...state,
        records: payload.transcripts,
      };
    case type.CLEAR_TRANSCRIPTS_LIST:
      return {
        ...state,
        records: [],
      };
    case type.SET_TRANSCRIPTS_LIST_LOADING:
      return {
        ...state,
        loadingList: payload.loading,
      };
    case type.SET_TRANSCRIPT_UPLOADING:
      return {
        ...state,
        loadingUpload: payload.loading,
      };
    case type.SET_TRANSCRIPTS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: payload.query !== "" ? payload.query : null,
      };
    case type.CLEAR_TRANSCRIPTS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: null,
      };
    case type.APPEND_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: [...state.deletionArray, payload.id],
      };
    case type.SUBTRACT_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: state.deletionArray.filter(
          (existing) => existing !== payload.id
        ),
      };
    case type.CLEAR_DELETION_ARRAY:
      return {
        ...state,
        deletionArray: [],
      };
    case type.SET_TRANSCRIPT_STATUS:
      return {
        ...state,
        records: {
          ...state.records,
          public: state.records.public.map((transcript: Transcription) => {
            if (transcript.id === payload.details.id) {
              return {
                ...transcript,
                status: payload.details.status,
                content: payload.details.content,
                progress: payload.details.progress,
              };
            }
            return transcript;
          }),
          private: state.records.private.map((transcript: Transcription) => {
            if (transcript.id === payload.details.id) {
              return {
                ...transcript,
                status: payload.details.status,
                content: payload.details.content,
                progress: payload.details.progress,
              };
            }
            return transcript;
          }),
        },
      };
    default:
      return state;
  }
};
