import styled from "@emotion/styled";

export const PageContainer = styled.div`
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
  @media (max-width: 767px) {
    padding-top: 1.5625rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const InnerPageContainer = styled.div`
  margin: 1.875rem !important;
  padding: 1.875rem;
  background-color: white;
  border-color: #eff2f5;
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  overflow: hidden;
  min-height: calc(100vh - 3.125rem - 5.4375rem - 2.5rem);
  @media (max-width: 767px) {
    margin: 0rem !important;
    padding: 0rem 0rem 0rem 0rem !important;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
  }
`;

export const NotificationWrapper = styled.div`
  margin: 1.875rem;
  @media (max-width: 767px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

export const UpperRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TitleCol = styled.div`
  display: flex;
  @media (max-width: 767px) {
    max-width: unset;
  }
`;

export const ButtonCol = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    padding-left: 0rem !important;
    button {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
`;

export const SearchContainer = styled.div`
  margin-top: 1.875rem;
  input {
    width: 100%;
    height: 3.125rem;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 1.5625rem;
`;

export const TableWrapper = styled.div`
  margin-top: 1.25rem;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  @media (max-width: 767px) {
    overflow-x: scroll;
  }
`;
