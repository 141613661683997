export const defaultApperance = {
  alignment: {
    horizontal: "center",
    vertical: "bottom",
  },
  backgroundColor: null,
  fontFamily: "Poppins",
  fontSize: "20",
  color: "#ffffff",
};
