import styled from "@emotion/styled";

export const SelectionWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;
