import { combineReducers } from "@reduxjs/toolkit";
import { user } from "./user";
import { transcripts } from "./transcripts";
import { transcript } from "./transcript";
import { workspace } from "./workspace";
import { blog } from "./blog";
import { article } from "./article";
import { blogposts } from "./blogposts";
import { blogpost } from "./blogpost";
import { soundtracks } from "./soundtracks";

const appReducer = combineReducers({
  user,
  transcripts,
  transcript,
  workspace,
  blog,
  article,
  blogposts,
  blogpost,
  soundtracks,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
