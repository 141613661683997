import {
  ToggleButton,
  ToggleButtonWrapper,
  ToggleGroupContainer,
} from "./styled";

interface IToggleButtons {
  selected: string;
  options: any[];
  onSelect: any;
}

const ToggleButtons: React.FC<IToggleButtons> = ({
  selected,
  options,
  onSelect,
}) => {
  return (
    <ToggleButtonWrapper>
      <ToggleGroupContainer>
        {options.map((option) => (
          <ToggleButton
            onClick={() => onSelect(option.value)}
            type="button"
            className={selected === option.value ? "active" : ""}
            aria-label={option.title}
          >
            {option.title}
          </ToggleButton>
        ))}
      </ToggleGroupContainer>
    </ToggleButtonWrapper>
  );
};

export default ToggleButtons;
