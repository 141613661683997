import * as type from "../types/transcripts";

export const createTranscript = (payload: any) => {
  return {
    type: type.REQUEST_CREATE_TRANSCRIPT,
    product: payload,
  };
};

export const clearCreateTranscript = () => {
  return {
    type: type.CLEAR_CREATE_TRANSCRIPT,
  };
};

export const setTranscriptsList = (payload: any) => {
  return {
    type: type.SET_TRANSCRIPTS_LIST,
    transcripts: payload,
  };
};

export const clearTranscriptsList = () => {
  return {
    type: type.CLEAR_TRANSCRIPTS_LIST,
  };
};

export const setTranscriptsLoading = (payload: any) => {
  return {
    type: type.SET_TRANSCRIPTS_LIST_LOADING,
    loading: payload,
  };
};

export const setTranscriptUploading = (payload: any) => {
  return {
    type: type.SET_TRANSCRIPT_UPLOADING,
    loading: payload,
  };
};

export const setSearchQuery = (payload: any) => {
  return {
    type: type.SET_TRANSCRIPTS_SEARCH_QUERY,
    query: payload,
  };
};

export const clearSearchQuery = () => {
  return {
    type: type.CLEAR_TRANSCRIPTS_SEARCH_QUERY,
  };
};

export const appendDeletionArray = (payload: any) => {
  return {
    type: type.APPEND_DELETION_ARRAY,
    id: payload,
  };
};

export const subtractDeletionArray = (payload: any) => {
  return {
    type: type.SUBTRACT_DELETION_ARRAY,
    id: payload,
  };
};

export const clearDeletionArray = () => {
  return {
    type: type.CLEAR_DELETION_ARRAY,
  };
};

export const setTranscriptStatus = (payload: any) => {
  return {
    type: type.SET_TRANSCRIPT_STATUS,
    details: payload,
  };
};
