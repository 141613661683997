import * as type from "../types/blogposts";

export const setBlogposts = (payload: any) => {
  return {
    type: type.SET_BLOGPOSTS_LIST,
    blogposts: payload,
  };
};

export const clearBlogposts = () => {
  return {
    type: type.CLEAR_BLOGPOSTS_LIST,
  };
};

export const setBlogpostsLoading = (payload: any) => {
  return {
    type: type.SET_BLOGPOSTS_LOADING,
    loading: payload,
  };
};

export const setSearchQuery = (payload: any) => {
  return {
    type: type.SET_BLOGPOSTS_SEARCH_QUERY,
    query: payload,
  };
};

export const appendDeletionArray = (payload: any) => {
  return {
    type: type.APPEND_DELETION_ARRAY,
    id: payload,
  };
};

export const subtractDeletionArray = (payload: any) => {
  return {
    type: type.SUBTRACT_DELETION_ARRAY,
    id: payload,
  };
};

export const clearDeletionArray = () => {
  return {
    type: type.CLEAR_DELETION_ARRAY,
  };
};
