import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingUserDetails,
  setUserCredentials,
} from "../redux/actions/user";
import { IState } from "../types";
const useUser = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state: IState) => state.user.loadingUserDetails);

  const fetchUserDetails = useCallback(
    async (uid: string) => {
      try {
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/user/details?uid=${uid}`
        );
        const response = await request.json();
        if (response.error === null) {
          dispatch(setUserCredentials(response.result));
          dispatch(setLoadingUserDetails(false));
        } else {
          throw response.error;
        }
      } catch (e) {
        dispatch(setLoadingUserDetails(false));
        throw e;
      }
    },
    [dispatch]
  );

  return { fetchUserDetails, loading };
};

export default useUser;
