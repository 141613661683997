import moment from "moment";
import {
  TableData,
  TableRow as FullTableRow,
  Value,
  ButtonsWrapper,
  ProgressWrapper,
  Status,
  ProgressBarWrapper,
} from "./styled";
import Checkbox from "../../../../../../library/checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  appendDeletionArray,
  subtractDeletionArray,
} from "../../../../../../redux/actions/transcripts";
import { useCallback, useEffect, useRef, useState } from "react";
import useMonitorTranscript from "../../../../../../hooks/useMonitorTranscript";
import ExportDetails from "../../../../../../components/export-modal";
import Button from "../../../../../../library/button";
import useModal from "../../../../../../hooks/useModal";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../../library/notification";
import useDeleteTranscript from "../../../../../../hooks/useDeleteTranscript";
import useListTranscripts from "../../../../../../hooks/useTranscriptsList";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { IState, Transcription } from "../../../../../../types";
import { Link } from "react-router-dom";
import ProgressBar from "../../../../../../library/progress-bar";
import Modal from "../../../../../../library/modal/new-modal";

const MOBILE_VIEW = 800;

interface ITableRowProps {
  data: Transcription;
  arr: any;
  index: number;
  navigationHeight: number;
}

const TableRow: React.FC<ITableRowProps> = ({ data, navigationHeight }) => {
  const pollInterval = 10000;

  const dispatch = useDispatch();
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const openExportModal = () => {
    setShowExportModal(true);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
  };

  const { t } = useTranslation();
  const { deleteTranscript } = useDeleteTranscript();
  const { fetchTranscriptsList } = useListTranscripts();
  const {
    openModal: openDeleteModal,
    Confirm: DeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { monitorTranscript } = useMonitorTranscript();

  const deletions = useSelector(
    (state: IState) => state.transcripts.deletionArray
  );

  const statusRef = useRef(data?.status);
  useEffect(() => {
    statusRef.current = data?.status;
  }, [data?.status]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const poll = useCallback(async () => {
    if (statusRef.current === "completed" || statusRef.current === "failed") {
      return;
    }
    try {
      await monitorTranscript(data?.id);
      setTimeout(poll, pollInterval);
    } catch (error) {
      // handle error if needed
    }
  }, [data?.id, monitorTranscript]);

  useEffect(() => {
    if (statusRef.current !== "completed" && statusRef.current !== "failed") {
      const timeoutId = setTimeout(poll, pollInterval);
      return () => clearTimeout(timeoutId);
    }
  }, [poll]);

  const handleSingleSelection = (e: any, id: string) => {
    if (e.target.checked) {
      if (!deletions.includes(id)) {
        dispatch(appendDeletionArray(id));
      }
    } else {
      if (deletions.includes(id)) {
        dispatch(subtractDeletionArray(id));
      }
    }
  };

  const renderDisplayColor = () => {
    switch (data?.status) {
      case "completed":
        return "completed";
      case "failed":
        return "failed";
      default:
        return "progress";
    }
  };

  const renderPercentage = (progress: number) => {
    return progress.toFixed(0) ?? 0;
  };

  const renderProgress = () => {
    switch (data?.status) {
      case "completed":
        return t("dashboard.pages.transcripts.table.data.statuses.completed");
      case "failed":
        return t("dashboard.pages.transcripts.table.data.statuses.failed");
      default:
        return (
          <ProgressBarWrapper>
            <ProgressBar
              className="full-progress-bar"
              label={`${renderPercentage(data?.progress)}%`}
              percentage={data?.progress}
              size="md"
              color="progress"
            />
          </ProgressBarWrapper>
        );
    }
  };

  const clickDeleteTranscript = async () => {
    try {
      const deletion = await deleteTranscript(data?.id);
      if (deletion.error === null) {
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.transcripts.delete-single.success"
          )
        );
        closeDeleteModal();
        fetchTranscriptsList();
      }
    } catch (e) {
      sendErrorNotification(
        t("dashboard.universal.notifications.transcripts.delete-single.failed")
      );
    }
  };

  const canEditTranscript = (): boolean => {
    return Array.isArray(data?.content);
  };

  return (
    <FullTableRow>
      <TableData className="select">
        <Checkbox
          onChange={(e: boolean) => handleSingleSelection(e, data?.id)}
          checked={deletions.includes(data?.id) ? true : false}
          name="product-select"
          ariaLabel={t("dashboard.universal.tables.select.ariaLabel") as string}
        />
      </TableData>
      <TableData>
        {canEditTranscript() ? (
          <Link to={`/dashboard/transcripts/${data?.id}`}>
            <Value className="bold title">
              {data?.status !== "completed" && (
                <ProgressWrapper>
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                </ProgressWrapper>
              )}
              {data?.filename
                ? data?.filename
                : t("dashboard.universal.text.not-available")}
            </Value>
          </Link>
        ) : (
          <Value className="bold title">
            {data?.status !== "completed" && (
              <ProgressWrapper>
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              </ProgressWrapper>
            )}
            {data?.filename
              ? data?.filename
              : t("dashboard.universal.text.not-available")}
          </Value>
        )}
      </TableData>
      <TableData className="date-display">
        <Value>{moment.unix(data?.timestamp).format("HH:mm DD/MM/YYYY")}</Value>
      </TableData>
      <TableData>
        <Value>
          <Status className={`${renderDisplayColor()}`}>
            {renderProgress()}
          </Status>
        </Value>
      </TableData>
      <TableData className="buttons">
        <ButtonsWrapper>
          <Button
            variant={windowWidth > MOBILE_VIEW ? "regular" : "icon"}
            icon="download"
            color="grey"
            size="md"
            onClick={openExportModal}
            ariaLabel={
              t(
                "dashboard.pages.transcripts.table.data.actions.download.ariaLabel"
              ) as string
            }
          >
            {t("dashboard.pages.transcripts.table.data.actions.download.title")}
          </Button>
          <Link to={`/dashboard/transcripts/${data?.id}`}>
            <Button size="md" color="blue" variant="icon" icon="editor" />
          </Link>
          <Button
            size="md"
            color="grey"
            variant="icon"
            icon="trash"
            onClick={openDeleteModal}
            ariaLabel={
              t(
                "dashboard.pages.transcripts.table.data.actions.delete.ariaLabel"
              ) as string
            }
          />
        </ButtonsWrapper>
      </TableData>
      <DeleteModal
        title={t("dashboard.pages.transcripts.delete-single-modal.title")}
        text={t("dashboard.pages.transcripts.delete-single-modal.text")!}
        onSubmit={clickDeleteTranscript}
        submitAriaLabel={
          t(
            "dashboard.pages.transcripts.delete-single-modal.buttons.delete.ariaLabel"
          )!
        }
        submitText={
          t(
            "dashboard.pages.transcripts.delete-single-modal.buttons.delete.title"
          )!
        }
      />
      <Modal
        onClose={closeExportModal}
        open={showExportModal}
        title="Last ned transkript"
      >
        <ExportDetails
          onClose={closeExportModal}
          transcriptId={data?.id}
          navigationHeight={navigationHeight}
          fullPageHeight={window.innerHeight}
        />
      </Modal>
    </FullTableRow>
  );
};

export default TableRow;
