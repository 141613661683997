import styled from "@emotion/styled";

export const TextWrapper = styled.div`
  text-align: center;
  display: block;
`;

export const TabsWrapper = styled.div`
  margin-top: 25px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Form = styled.form``;

export const StyledText = styled.span`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 1rem;
  display: block;
  @media (max-width: 768px) {
    font-size: 0.875rem;
    display: block;
  }
`;
