import * as type from "../types/workspace";

const initialState = {
  details: {
    thumbnail: null,
    name: null,
    type: null,
    dateCreated: null,
    subscription: null,
    licenses: {
      used: 0,
      total: 0,
    },
  },
  loadingDetails: true,
  members: [],
  loadingMembers: true,
};

export const workspace = (state = initialState, payload: any) => {
  switch (payload.type) {
    case type.SET_WORKSPACE_DETAILS:
      return {
        ...state,
        details: payload.details,
      };
    case type.SET_LOADING_WORKSPACE_DETAILS:
      return {
        ...state,
        loadingDetails: payload.loading,
      };
    case type.SET_MEMBERS_LIST:
      return {
        ...state,
        members: payload.members,
      };
    case type.SET_LOADING_MEMBERS_LIST:
      return {
        ...state,
        loadingMembers: payload.loading,
      };
    default:
      return state;
  }
};
