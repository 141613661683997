import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const TopWrapper = styled.div`
  margin: 1.875rem;
  @media (max-width: 975px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

export const UpperTopWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const UpperRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  @media (max-width: 975px) {
    display: block;
  }
`;

export const LastSavedLabel = styled.span`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 14px;
  margin-left: 20px;
  i {
    margin-right: 5px;
  }
`;

export const TitleCol = styled(Col)`
  padding-left: 0rem;
  @media (max-width: 975px) {
    max-width: unset;
  }
`;

export const DetailsWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.span`
  @media (max-width: 975px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ButtonCol = styled(Col)`
  text-align: right;
  padding-right: 0rem;
  max-width: 325px;
  .dropdown {
    display: inline !important;
  }
  @media (max-width: 975px) {
    padding-left: 0rem !important;
    margin-top: 0.9375rem;
    button {
      display: block;
      min-width: 100%;
      &:last-of-type {
        margin-top: 0.625rem;
      }
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: inline-flex !important;
  button {
    margin-left: 0.9375rem;
  }
`;
