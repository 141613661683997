import * as type from "../types/user";

const initialState = {
  details: {},
  workspaces: [],
  loadingWorkspaces: true,
  loadingUserDetails: true,
  selectedWorkspace: {
    id: null,
    name: null,
    status: null,
  },
  fetchDetails: {
    status: null,
    loading: false,
    error: null,
  },
};

export const user = (state = initialState, payload: any) => {
  switch (payload.type) {
    case type.SET_USER_DETAILS:
      return {
        ...state,
        details: payload.details,
      };
    case type.SET_LOADING_WORKSPACES:
      return {
        ...state,
        loadingWorkspaces: payload.loading,
      };
    case type.SET_LOADING_USER_DETAILS:
      return {
        ...state,
        loadingUserDetails: payload.loading,
      };
    case type.SET_USER_WORKSPACES:
      return {
        ...state,
        workspaces: payload.workspaces,
      };
    case type.SET_ACTIVE_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: payload.workspace,
      };
    case type.UPDATE_WORKSPACE_SUBSCRIPTION:
      return {
        ...state,
        selectedWorkspace: {
          ...state.selectedWorkspace,
          status: payload.result.status,
        },
        workspaces: state.workspaces.map((workspace: any) => {
          if (workspace.id === payload.result.id) {
            return {
              ...workspace,
              status: payload.result.status,
            };
          }
          return workspace;
        }),
      };
    case type.CLEAR_USER_STATE:
      return {
        details: null,
        workspaces: [],
        loadingWorkspaces: true,
        loadingUserDetails: true,
        selectedWorkspace: {
          id: null,
          name: null,
          status: null,
        },
        fetchDetails: {
          status: null,
          loading: false,
          error: null,
        },
      };
    case type.SET_USER_ONBOARDED:
      return {
        ...state,
        details: {
          ...state.details,
          onboarded: true,
        },
      };
    default:
      return state;
  }
};
