import * as type from "../types/transcript";

const initialState = {
  saveLoading: false,
  descriptions: [],
  keywords: [],
  questions: [],
  data: {
    filename: "",
    public: false,
    author: "",
    format: "",
    language: "",
    title: "",
    timestamp: 0,
    progress: 0,
    content: "",
    status: 0,
    id: null,
  },
};

export const transcript = (state = initialState, payload: any) => {
  switch (payload.type) {
    case type.SET_ACTIVE_TRANSCRIPT:
      return {
        ...state,
        data: payload.data,
      };
    case type.CLEAR_ACTIVE_TRANSCRIPT:
      return {
        ...state,
        data: [],
      };
    case type.SET_TRANSCRIPT_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          lastUpdated: payload.timestamp,
        },
      };
    case type.SET_SAVE_LOADING_TRANSCRIPT:
      return {
        ...state,
        saveLoading: payload.loading,
      };
    default:
      return state;
  }
};
