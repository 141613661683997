import styled from "@emotion/styled";

export const PlayerWrapper = styled.div<{ navigationHeight?: number }>`
  width: 100%;
  height: auto;
  padding-bottom: 80px;
  z-index: 1;
  @media (max-width: 975px) {
    position: fixed;
    top: ${({ navigationHeight }) => navigationHeight}px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: block;
`;

export const Video = styled.video`
  display: block;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  border-radius: 1rem;
  background-color: #000;
  max-height: calc(90vh - 119px - 95px - 80px - 20px);
  @media (max-width: 975px) {
    border-radius: 0rem;
  }
`;

export const SubtitleWrapper = styled.div`
  position: absolute;
  text-align: center;
  padding: 0px 20px;
  width: 100%;

  &.vertical-top {
    top: 5%;
  }
  &.vertical-center {
    top: 50%;
    transform: translateY(-50%);
  }
  &.vertical-bottom {
    bottom: 5%;
  }
`;

export const ActiveSubtitle = styled.span`
  position: relative;
  color: white;
  text-shadow: 1px 0.5px 2px black;
  display: inline;
  line-height: 1.1;
  @media (max-width: 975px) {
    font-size: 2.8vw;
  }
`;

export const Track = styled.track``;
