import { Dispatch, SetStateAction, useState } from "react";
import Button from "../../../../../../library/button";
import Modal from "../../../../../../library/modal/new-modal";
import ExportDetails from "../../../../../../components/export-modal";
import {
  InnerLeftWrapper,
  InnerRightWrapper,
  InnerWrapper,
  TopWrapper,
} from "./styled";

interface MobileTopBarProps {
  onGoBack: () => void;
  onSave: () => void;
  onGenerateVideo: () => void;
  generateLoading: boolean;
  saveLoading: boolean;
  transcriptId?: string;
  captions: any;
  appearance: any;
  progress: number;
  videoDownloadUrl?: string;
  setVideoDownloadUrl?: Dispatch<SetStateAction<string | undefined>>;
  navigationHeight?: number;
}

const MobileTopBar: React.FC<MobileTopBarProps> = ({
  onGoBack,
  onSave,
  saveLoading,
  transcriptId,
  captions,
  appearance,
  onGenerateVideo,
  generateLoading,
  progress,
  videoDownloadUrl,
  setVideoDownloadUrl,
  navigationHeight,
}) => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const openExportModal = () => {
    setShowExportModal(true);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    if (videoDownloadUrl) {
      setVideoDownloadUrl && setVideoDownloadUrl(undefined);
    }
  };

  const onExport = () => {
    openExportModal();
  };

  return (
    <>
      <TopWrapper>
        <InnerWrapper>
          <InnerLeftWrapper>
            <Button
              onClick={onGoBack}
              variant="icon"
              color="grey"
              size="lg"
              icon={"back"}
            />
          </InnerLeftWrapper>
          <InnerRightWrapper>
            <Button
              onClick={onSave}
              variant="icon"
              color="grey"
              size="lg"
              icon={saveLoading ? "spinner" : "save"}
            />
            <Button onClick={onExport} variant="regular" color="blue">
              Eksporter
            </Button>
          </InnerRightWrapper>
        </InnerWrapper>
      </TopWrapper>
      <Modal
        open={showExportModal}
        onClose={closeExportModal}
        title="Eksporter"
      >
        <ExportDetails
          onClose={closeExportModal}
          transcriptId={transcriptId ?? ""}
          captions={captions}
          configuration={appearance}
          generateLoading={generateLoading}
          onGenerateVideo={onGenerateVideo}
          variant="editor"
          progress={progress}
          videoDownloadUrl={videoDownloadUrl}
          navigationHeight={navigationHeight}
          fullPageHeight={window.innerHeight}
        />
      </Modal>
    </>
  );
};

export default MobileTopBar;
