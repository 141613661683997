import styled from "@emotion/styled";

export const ControllerWrapper = styled.div`
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div<{ visible: boolean }>`
  color: white;
  font-size: 48px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: none; /* Prevent the icon from intercepting clicks */
`;
