import { toast } from "react-toastify";

export const sendSuccessNotification = (title: string) => {
  toast.success(title, {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "success-popup",
    hideProgressBar: true,
  });
};

export const sendErrorNotification = (title: string) => {
  toast.error(title, {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "error-popup",
    hideProgressBar: true,
  });
};
