import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const InnerPageContainer = styled.div`
  margin: 1.875rem !important;
  padding: 1.875rem;
  background-color: white;
  border-radius: 0.5rem;
  border-color: #eff2f5;
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  overflow: hidden;
  @media (max-width: 767px) {
    margin: 0rem !important;
    padding: 0rem 0rem 0rem 0rem !important;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    margin-bottom: 1.5625rem !important;
  }
`;

export const DetailsRow = styled(Row)`
  @media (max-width: 767px) {
    display: block;
  }
`;

export const ThumbnailCol = styled(Col)`
  max-width: 9.375rem;
`;

export const ThumbnailWrapper = styled.div``;

export const Thumbnail = styled.img`
  width: 100%;
  border-radius: 0.5rem;
`;

export const InformationCol = styled(Col)`
  @media (max-width: 767px) {
    padding-top: 0.9375rem;
  }
`;

export const Name = styled.h2`
  font-family: "Poppins", sans-serif;
`;

export const Text = styled.span`
  display: block;
  font-size: 0.875rem;
  color: grey;
  font-family: "Poppins", sans-serif;
`;

export const ProgressTitle = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  color: grey;
  text-transform: uppercase;
  padding-top: 0.625rem;
  display: block;
`;

export const VerifiedIcon = styled.span`
  color: #2f8de4;
`;

export const ProgressBarWrapper = styled.div`
  margin-top: 0.4688rem;
  max-width: 21.875rem;
`;

export const ButtonsCol = styled(Col)`
  text-align: right;
  @media (max-width: 767px) {
    display: block;
    text-align: left;
    padding-top: 0.9375rem;
    button {
      width: 100%;
      &:last-of-type {
        margin-top: 0.9375rem;
      }
    }
  }
`;

export const TitleBadge = styled.span`
  font-size: 0.875rem;
  padding: 0.5rem 0.9375rem;
  color: #2f8de4 !important;
  font-size: 0.75rem;
  background-color: #e5f0f9;
  border-radius: 0.5rem;
  margin-left: 0.625rem;
  position: relative;
  top: -0.3125rem;
`;
