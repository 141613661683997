import styled from "@emotion/styled";

export const FullWrapper = styled.div`
  position: relative;
`;

export const ColorWrapper = styled.div`
  background-color: #f4f4f4;
  padding: 10px;
  height: 49px;
  border-radius: 0.875rem;
  margin-top: 0.3125rem;
`;

export const InnerColorWrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    position: absolute;
    right: 5px;
  }
`;

export const ColorIndicator = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 0.5rem;
  background-color: #ffffff;
`;

export const ColorText = styled.span`
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
`;

export const SelectorWrapper = styled.div`
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
`;
