import { useCallback } from "react";
import { firebaseApp } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { IState } from "../types";
import {
  setSoundtracksList,
  setSoundtracksLoading,
} from "../redux/actions/soundtracks";

const useListSoundtracks = () => {
  const auth = getAuth(firebaseApp);
  const dispatch = useDispatch();

  const loading = useSelector((state: IState) => state.soundtracks.loadingList);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const fetchSoundtracksList = useCallback(async () => {
    try {
      dispatch(setSoundtracksLoading(true));
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/soundtracks?workspaceId=${selectedWorkspace.id}&token=${token}`
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(setSoundtracksList(response.status));
        dispatch(setSoundtracksLoading(false));
      }
    } catch (e) {
      dispatch(setSoundtracksList([]));
      dispatch(setSoundtracksLoading(false));
      throw e;
    }
  }, [dispatch, auth, selectedWorkspace?.id]);

  return { fetchSoundtracksList, loading };
};

export default useListSoundtracks;
