import {
  AttentionBoxContainer,
  ButtonCol,
  InnerPageContainer,
  PageContainer,
  TitleCol,
  UpperRow,
} from "./styled";
import { useEffect, useState } from "react";
import AccessView from "./access-view";
import { useSelector } from "react-redux";
import useWorkspaceDetails from "../../../../hooks/useWorkspaceDetails";
import TopBar from "./top-bar";
import { useTranslation } from "react-i18next";
import { IState } from "../../../../types";
import Meta from "./meta";
import AttentionBox from "../../../../library/attention-box";
import { Title } from "../../../../library/title";
import Button from "../../../../library/button";
import Modal from "../../../../library/modal/new-modal";
import InviteModalContent from "./invite-modal";

const Index = () => {
  const { t } = useTranslation();
  const { fetchWorkspaceDetails } = useWorkspaceDetails();
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const user = useSelector((state: IState) => state.user.details);

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid) {
      fetchWorkspaceDetails();
    }
  }, [fetchWorkspaceDetails, selectedWorkspace?.id, user?.uid]);

  const openInviteModal = () => {
    setInviteModalOpen(true);
  };

  const closeInvitationModal = () => {
    setInviteModalOpen(false);
  };

  return (
    <>
      <Meta />
      <PageContainer>
        <AttentionBoxContainer>
          <AttentionBox
            type="information"
            icon="information"
            title={t("dashboard.pages.workspace.attention-box.title")}
            text={t("dashboard.pages.workspace.attention-box.text")}
          />
        </AttentionBoxContainer>
        <TopBar />
        <InnerPageContainer>
          <UpperRow>
            <TitleCol className="my-auto">
              <Title>{t("dashboard.pages.workspace.bottom-view.title")}</Title>
            </TitleCol>
            <ButtonCol>
              <Button
                onClick={() => openInviteModal()}
                color="blue"
                size="md"
                variant="regular"
                ariaLabel={
                  t(
                    "dashboard.pages.workspace.top-view.buttons-column.buttons.invite-user.ariaLabel"
                  ) as string
                }
              >
                {t(
                  "dashboard.pages.workspace.top-view.buttons-column.buttons.invite-user.title"
                )}
              </Button>
            </ButtonCol>
          </UpperRow>
          <AccessView />
        </InnerPageContainer>
        <Modal
          open={inviteModalOpen}
          onClose={closeInvitationModal}
          title={t("dashboard.pages.workspace.invite-modal.title")}
        >
          <InviteModalContent onClose={closeInvitationModal} />
        </Modal>
      </PageContainer>
    </>
  );
};

export default Index;
