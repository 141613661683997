import { useTranslation } from "react-i18next";
import useMembersList from "../../../../../hooks/useMembersList";
import TableLoader from "../../../../../library/table-loader";
import { Container } from "./styled";
import ListView from "./list-view";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../../../../types";

const AccessView = () => {
  const { t } = useTranslation();
  const { loading } = useMembersList();
  const { fetchMembersList } = useMembersList();

  const selectedWorkspace = useSelector(
    (state: IState) => state.user?.selectedWorkspace
  );
  const user = useSelector((state: IState) => state.user?.details);

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid) {
      fetchMembersList();
    }
  }, [fetchMembersList, selectedWorkspace?.id, user?.uid]);

  return (
    <Container>
      {loading ? (
        <TableLoader
          message={
            t(
              "dashboard.pages.workspace.bottom-view.loading-view.message"
            ) as string
          }
        />
      ) : (
        <ListView />
      )}
    </Container>
  );
};

export default AccessView;
