import styled from "@emotion/styled";
import { Row, Col } from "react-bootstrap";

export const PageContainer = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  @media (max-width: 767px) {
    padding-top: 25px;
  }
`;

export const InnerPageContainer = styled.div`
  margin: 30px !important;
  padding: 30px;
  background-color: white;
  border-color: #eff2f5;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #e2e2e2;
  overflow: hidden;
  min-height: calc(100vh - 50px - 87px - 40px);
  @media (max-width: 767px) {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
  }
`;

export const UpperRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const TitleCol = styled(Col)`
  padding-left: 0px;
  max-width: 150px;
  @media (max-width: 767px) {
    max-width: unset;
  }
`;

export const ButtonCol = styled(Col)`
  text-align: right;
  padding-right: 0px;
  @media (max-width: 767px) {
    padding-left: 0px !important;
    margin-top: 15px;
    button {
      display: block;
      min-width: 100%;
      &:last-of-type {
        margin-top: 10px;
      }
    }
  }
`;

export const SearchContainer = styled.div`
  margin-top: 30px;
  input {
    width: 100%;
    height: 50px;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 25px;
`;

export const TableWrapper = styled.div`
  margin-top: 20px;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;
