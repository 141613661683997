import { useEffect, useState } from "react";
import {
  Video,
  PlayerWrapper,
  VideoWrapper,
  ActiveSubtitle,
  SubtitleWrapper,
} from "./styled";
import VideoPlayerDesktop from "./desktop";
import VideoPlayerMobile from "./mobile";

type VideoPlayerProps = {
  videoUrl: string;
  mobileView: number;
  windowWidth: number;
  captions: any;
  configuration: any;
  currentTime: any;
  onTimeUpdate: any;
  videoRef: any;
  navigationHeight: number;
};

const VideoPlayer = ({
  videoUrl,
  mobileView,
  windowWidth,
  captions,
  configuration,
  onTimeUpdate,
  videoRef,
  currentTime,
  navigationHeight,
}: VideoPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [mute, setMute] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current?.paused || videoRef.current?.ended) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current?.pause();
      setIsPlaying(false);
    }
  };

  const handleMute = () => {
    if (!videoRef?.current) return;
    videoRef.current.muted = !mute;
    setMute(!mute);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!videoRef?.current) return;
    const value = e.target.value;
    const volume = parseFloat(value);
    videoRef.current.volume = volume;
    document.documentElement.style.setProperty(
      "--player-volume",
      `${volume * 100}%`
    );
    setVolume(volume);
    setMute(volume === 0);
  };

  const activeSubtitle = () => {
    if (!captions) return "";
    const currentCaption = captions.find(
      (caption: any) =>
        currentTime >= caption.start && currentTime <= caption.end
    );
    return currentCaption ? currentCaption?.text : "";
  };

  const clickFullscreen = () => {
    if (!videoRef.current) return;

    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
  };

  const clickGoBackward = () => {
    if (!videoRef.current) return;
    const skipTime = currentTime - 5;
    videoRef.current.currentTime = skipTime;
  };

  const clickGoForward = () => {
    if (!videoRef.current) return;
    const skipTime = currentTime + 5;
    videoRef.current.currentTime = skipTime;
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.code === "Space" &&
        event.target instanceof HTMLElement &&
        !["INPUT", "TEXTAREA"].includes(event.target.tagName)
      ) {
        if (videoRef.current.paused || videoRef.current.ended) {
          videoRef.current.play();
          setIsPlaying(true);
        } else {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [videoRef]);

  const clickStartBeginning = () => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = 0;
  };

  return (
    <PlayerWrapper navigationHeight={navigationHeight}>
      <VideoWrapper>
        <Video
          ref={videoRef}
          src={videoUrl}
          onTimeUpdate={onTimeUpdate}
          preload="metadata"
          playsInline
          controls={false}
        >
          Your browser does not support the video tag.
        </Video>
        <SubtitleWrapper
          className={`vertical-${configuration?.alignment?.vertical}`}
          style={{
            textAlign: configuration?.alignment?.horizontal || "center",
          }}
        >
          <ActiveSubtitle
            style={{
              fontFamily: configuration?.fontFamily,
              fontSize: `${configuration?.fontSize}px`,
              color: configuration?.color,

              backgroundColor: configuration?.backgroundColor || "transparent",
            }}
          >
            {activeSubtitle()}
          </ActiveSubtitle>
        </SubtitleWrapper>
      </VideoWrapper>
      {windowWidth > mobileView ? (
        <VideoPlayerDesktop
          clickGoBackward={clickGoBackward}
          togglePlayPause={togglePlayPause}
          clickStartBeginning={clickStartBeginning}
          isPlaying={isPlaying}
          mute={mute}
          handleMute={handleMute}
          volume={volume}
          videoRef={videoRef}
          clickFullscreen={clickFullscreen}
          currentTime={currentTime}
          clickGoForward={clickGoForward}
          handleVolumeChange={handleVolumeChange}
        />
      ) : (
        <VideoPlayerMobile
          togglePlayPause={togglePlayPause}
          isPlaying={isPlaying}
        />
      )}
    </PlayerWrapper>
  );
};

export default VideoPlayer;
