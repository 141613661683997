import * as type from "../types/soundtracks";

export const createSoundtrack = (payload: any) => {
  return {
    type: type.REQUEST_CREATE_SOUNDTRACK,
    product: payload,
  };
};

export const clearCreateSoundtrack = () => {
  return {
    type: type.CLEAR_CREATE_SOUNDTRACK,
  };
};

export const setSoundtracksList = (payload: any) => {
  return {
    type: type.SET_SOUNDTRACKS_LIST,
    soundtracks: payload,
  };
};

export const clearSoundtracksList = () => {
  return {
    type: type.CLEAR_SOUNDTRACKS_LIST,
  };
};

export const setSoundtracksLoading = (payload: any) => {
  return {
    type: type.SET_SOUNDTRACKS_LIST_LOADING,
    loading: payload,
  };
};

export const setSoundtrackCreationLoading = (payload: any) => {
  return {
    type: type.SET_SOUNDTRACK_CREATION_LOADING,
    loading: payload,
  };
};

export const setSearchQuery = (payload: any) => {
  return {
    type: type.SET_SOUNDTRACKS_SEARCH_QUERY,
    query: payload,
  };
};

export const clearSearchQuery = () => {
  return {
    type: type.CLEAR_SOUNDTRACKS_SEARCH_QUERY,
  };
};

export const appendDeletionArray = (payload: any) => {
  return {
    type: type.APPEND_DELETION_ARRAY,
    id: payload,
  };
};

export const subtractDeletionArray = (payload: any) => {
  return {
    type: type.SUBTRACT_DELETION_ARRAY,
    id: payload,
  };
};

export const clearDeletionArray = () => {
  return {
    type: type.CLEAR_DELETION_ARRAY,
  };
};

export const setSoundtrackStatus = (payload: any) => {
  return {
    type: type.SET_SOUNDTRACK_STATUS,
    details: payload,
  };
};
