import * as type from "../../types/article";

const initialState = {
  content: {
    title: "",
    content: "",
    timestamp: "",
    excerpt: "",
    status: "",
    thumbnail: "",
  },
  loadingArticle: true,
};

export const article = (state = initialState, payload: any) => {
  switch (payload.type) {
    case type.SET_ARTICLE:
      return {
        ...state,
        content: payload.article,
      };
    case type.SET_ARTICLE_LOADING:
      return {
        ...state,
        loadingArticle: payload.loading,
      };
    default:
      return state;
  }
};
