import { FileDrop } from "react-file-drop";
import { ErrorWrapper, InteractionContainer } from "./styled";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IState } from "../../../../../../../types";
import UploadingView from "./uploading-view";
import DroppedView from "./dropped";
import NotDroppedView from "./drop-view";
import { Text } from "../../../../../../../library/text";
import { sendErrorNotification } from "../../../../../../../library/notification";
import AttentionBox from "../../../../../../../library/attention-box";
import { UseFormSetValue } from "react-hook-form";
import { UploadTranscriptSchema } from "../../schema";
import { useEffect, useState } from "react";

interface UploadViewProps {
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  selectedFile: File;
  errors: any;
  progress: number;
}

const MOBILE_VIEW = 768;

const UploadView: React.FC<UploadViewProps> = ({
  setValue,
  selectedFile,
  errors,
  progress,
}) => {
  const { t } = useTranslation();
  const uploading = useSelector(
    (state: IState) => state.transcripts.loadingUpload
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isFileTypeAllowed = (file: File): boolean => {
    const allowedFormats = [
      ".m4a",
      ".mp3",
      ".webm",
      ".mp4",
      ".mpga",
      ".wav",
      ".mpeg",
      ".ogg",
      ".heic",
      ".mov",
      ".avi",
    ];

    const fileExtension = `.${file.name.split(".").pop()}`;

    return allowedFormats.some(
      (format) => format === fileExtension.toLowerCase()
    );
  };

  const handleFileDrop = (e: any, type: string) => {
    try {
      if (type === "click") {
        if (isFileTypeAllowed(e.target.files[0])) {
          setValue("file", e.target.files[0]);
        } else {
          throw Error("Filtype støttes ikke");
        }
      } else {
        if (isFileTypeAllowed(e[0])) {
          setValue("file", e[0]);
        } else {
          throw Error("Filtype støttes ikke");
        }
      }
    } catch (e) {
      sendErrorNotification("Filtypen du har valgt støttes ikke.");
    }
  };

  const renderError = (type: "text" | "visual") => {
    if (!selectedFile && errors["file"]?.message) {
      return type === "visual" ? "error" : errors["file"]?.message;
    }
    return "";
  };

  return (
    <div>
      <FileDrop onDrop={(f) => handleFileDrop(f, "dragdrop")}>
        {windowWidth > MOBILE_VIEW && (
          <AttentionBox
            icon="information"
            title="Tips til store videofiler"
            text="Dersom du har en veldig stor videofil kan det være lurt å komprimere den før du laster opp. "
            type="information"
          />
        )}

        <InteractionContainer
          aria-label={
            t(
              "dashboard.pages.transcripts.add-modal.content.source-step.upload-container.ariaLabel"
            ) as string
          }
          className={`${renderError("visual")}`}
        >
          {uploading ? (
            <UploadingView progress={progress} />
          ) : (
            <>
              {selectedFile?.name ? (
                <DroppedView selectedFile={selectedFile} setValue={setValue} />
              ) : (
                <NotDroppedView handleFileDrop={handleFileDrop} />
              )}
            </>
          )}
          {renderError("text") && (
            <ErrorWrapper>
              <Text color="fail" size="small">
                {renderError("text")}
              </Text>
            </ErrorWrapper>
          )}
        </InteractionContainer>
      </FileDrop>
    </div>
  );
};

export default UploadView;
