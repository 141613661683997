import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const AttentionBoxContainer = styled.div`
  padding: 1.875rem;
  padding-bottom: 0px;
`;

export const PageContainer = styled.div`
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
  @media (max-width: 767px) {
    padding-top: 3.125rem;
  }
`;

export const InnerPageContainer = styled.div`
  margin: 1.875rem !important;
  padding: 1.875rem;
  background-color: white;
  border-color: #eff2f5;
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  overflow: hidden;
  @media (max-width: 767px) {
    margin: 0rem !important;
    padding: 0rem 0rem 0rem 0rem !important;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
  }
`;

export const BottomRow = styled(Row)``;

export const Text = styled.span`
  color: grey;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const BottomCol = styled(Col)``;

export const UpperRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const TitleCol = styled(Col)`
  padding-left: 0rem;
  max-width: 9.375rem;
  @media (max-width: 767px) {
    max-width: unset;
  }
`;

export const ButtonCol = styled(Col)`
  text-align: right;
  padding-right: 0rem;
  @media (max-width: 767px) {
    padding-left: 0rem !important;
    margin-top: 0.9375rem;
    button {
      display: block;
      min-width: 100%;
      &:last-of-type {
        margin-top: 0.625rem;
      }
    }
  }
`;
