import styled from "@emotion/styled";

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  padding: 0.7813rem 1.25rem;
  border-radius: 0.875rem;
  border: 0.0625rem solid #f1f6ff;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: #f4f4f4;
  &.variant-search {
    padding-left: 2.5rem;
  }
  &:focus {
    outline: 0.125rem solid #2f8de4 !important;
  }
  &.disabled {
    opacity: 0.56;
  }
  &.error {
    outline: 2px solid #ed0c60;
  }
`;

export const SearchIcon = styled.i`
  position: absolute;
  left: 1rem;
  top: 1rem;
  color: grey;
`;

export const ErrorWrapper = styled.div`
  padding-left: 15px;
`;
