import * as type from "../types/transcript";

export const setActiveTranscript = (payload: any) => {
  return {
    type: type.SET_ACTIVE_TRANSCRIPT,
    data: payload,
  };
};

export const setTranscriptUpdate = (payload: any) => {
  return {
    type: type.SET_TRANSCRIPT_UPDATE,
    timestamp: payload,
  };
};

export const setSaveLoadingTranscript = (payload: any) => {
  return {
    type: type.SET_SAVE_LOADING_TRANSCRIPT,
    loading: payload,
  };
};
