import { ChangeEvent } from "react";
import { Label } from "../../../../../library/label";
import { Select } from "../../../../../library/select";
import { AppearanceWrapper, Field, InputCol, InputRow } from "./styled";
import Input from "../../../../../library/input";
import { ColorResult } from "react-color";
import { UseFormSetValue } from "react-hook-form";
import { VideoAppearanceSchema } from "../schema";
import ToggleButtons from "../../../../../library/toggle-buttons";
import { getIcon } from "../../../../../utils/get-icon";
import ColorSelect from "../../../../../library/color-select";

type AppearanceProps = {
  fonts: any;
  register: any;
  appearance: any;
  setValue: UseFormSetValue<VideoAppearanceSchema>;
  updateGoogleFontsLink: any;
};

const Index = ({
  fonts,
  register,
  appearance,
  setValue,
  updateGoogleFontsLink,
}: AppearanceProps) => {
  const handleFontSelect = (family: string) => {
    const selectedFont = fonts.find((font: any) => font.family === family);
    if (selectedFont) {
      updateGoogleFontsLink(selectedFont);
      setValue("appearance.fontFamily", selectedFont.family);
    }
  };

  const renderFonts = () => {
    if (fonts && fonts?.length > 0) {
      let allFonts: any = [];
      fonts.forEach((font: any) => {
        allFonts.push({ value: font.family, title: font.family });
      });
      return allFonts;
    }
    return [];
  };

  const handleColorChange = (color: ColorResult) => {
    setValue("appearance.color", color.hex);
  };

  const handleBackgroundColorChange = (color: ColorResult) => {
    setValue("appearance.backgroundColor", color.hex);
  };

  const handleHorizontalSelection = (
    alignment: "left" | "center" | "right"
  ) => {
    setValue("appearance.alignment.horizontal", alignment);
  };

  const handleVerticalSelection = (alignment: "top" | "center" | "bottom") => {
    setValue("appearance.alignment.vertical", alignment);
  };

  const onResetFontColor = () => {
    setValue("appearance.color", "#ffffff");
  };

  const onResetFontBackground = () => {
    setValue("appearance.backgroundColor", null);
  };

  return (
    <AppearanceWrapper>
      <Field>
        <Label>Posisjonering</Label>
        <InputRow>
          <InputCol xs={6}>
            <ToggleButtons
              selected={appearance?.alignment?.horizontal}
              options={[
                {
                  title: getIcon("align-horizontal-left"),
                  value: "left",
                },
                {
                  title: getIcon("align-horizontal-center"),
                  value: "center",
                },
                {
                  title: getIcon("align-horizontal-right"),
                  value: "right",
                },
              ]}
              onSelect={handleHorizontalSelection}
            />
          </InputCol>
          <InputCol xs={6}>
            <ToggleButtons
              selected={appearance?.alignment?.vertical}
              options={[
                {
                  title: getIcon("align-vertical-top"),
                  value: "top",
                },
                {
                  title: getIcon("align-vertical-center"),
                  value: "center",
                },
                {
                  title: getIcon("align-vertical-bottom"),
                  value: "bottom",
                },
              ]}
              onSelect={handleVerticalSelection}
            />
          </InputCol>
        </InputRow>
      </Field>
      <Field>
        <InputRow>
          <InputCol md={9}>
            <Label>Font</Label>
            <Select
              items={renderFonts()}
              onChange={(v: ChangeEvent<HTMLSelectElement>) =>
                handleFontSelect(v.target.value)
              }
              selected={appearance.fontFamily}
              id="languageSelect"
              ariaLabel=""
            />
          </InputCol>
          <InputCol md={3}>
            <Label>Størrelse</Label>
            <Input
              variant="numeric"
              register={register}
              name="appearance.fontSize"
            />
          </InputCol>
        </InputRow>
      </Field>
      <Field>
        <InputRow>
          <InputCol>
            <Label>Farge</Label>
            <ColorSelect
              color={appearance?.color}
              onChange={handleColorChange}
              onReset={onResetFontColor}
            />
          </InputCol>
          <InputCol>
            <Label>Bakgrunn</Label>
            <ColorSelect
              color={appearance?.backgroundColor}
              onChange={handleBackgroundColorChange}
              onReset={onResetFontBackground}
            />
          </InputCol>
        </InputRow>
      </Field>
    </AppearanceWrapper>
  );
};

export default Index;
