import { Dashboard } from "./styled";
import Navigation from "../../components/dashboard-upper-navigation";

interface IDashboardLayoutProps {
  navigationRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

const DashboardLayout: React.FC<IDashboardLayoutProps> = ({
  navigationRef,
  children,
}) => {
  return (
    <Dashboard>
      <Navigation navigationRef={navigationRef} />
      {children}
    </Dashboard>
  );
};

export default DashboardLayout;
