import { useSelector } from "react-redux";
import { Title } from "../../../library/title";
import useFetchBlogposts from "../../../hooks/useFetchBlogposts";
import { IState } from "../../../types";
import "./styled";
import {
  ArticleCol,
  ArticleWrapper,
  Container,
  Excerpt,
  NewsRow,
  Published,
  Section,
  Thumbnail,
  Wrapper,
} from "./styled";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import TableLoader from "../../../library/table-loader";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const { loading, fetchBlogposts } = useFetchBlogposts();
  const blogposts = useSelector((state: IState) => state?.blog?.blogposts);

  useEffect(() => {
    fetchBlogposts(3);
  }, [fetchBlogposts]);

  const renderView = () => {
    if (loading) {
      return <TableLoader />;
    }
    return (
      <NewsRow data-testid="blogposts">
        {blogposts?.map((post) => (
          <ArticleCol lg={4}>
            <Link to={`/article/${post.prefix}`}>
              <ArticleWrapper>
                <Thumbnail
                  alt={`Randi - ${post.title}`}
                  src={post?.thumbnail}
                />
                <Published>
                  Publisert{" "}
                  {moment.unix(post.timestamp).format("HH:mm DD/MM/YYYY")}
                </Published>
                <Title>{post.title}</Title>
                <Excerpt>{post.excerpt}</Excerpt>
              </ArticleWrapper>
            </Link>
          </ArticleCol>
        ))}
      </NewsRow>
    );
  };

  return (
    <Section>
      <Container>
        <Wrapper>
          <Title>{t("frontpage.sections.blog-view.title")}</Title>
          {renderView()}
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Index;
