import { useState } from "react";
import {
  ColorIndicator,
  ColorText,
  ColorWrapper,
  FullWrapper,
  InnerColorWrapper,
  SelectorWrapper,
} from "./styled";
import { useDetectClickOutside } from "react-detect-click-outside";
import { SketchPicker } from "react-color";
import Button from "../button";

interface IColorSelectProps {
  onChange: any;
  color: string;
  onReset: any;
}

const ColorSelect: React.FC<IColorSelectProps> = ({
  onChange,
  color,
  onReset,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOutside = () => {
    setOpen(false);
  };

  const ref = useDetectClickOutside({ onTriggered: handleClickOutside });

  const clickSelect = () => {
    setOpen(!open);
  };

  const clickReset = () => {
    setOpen(false);
    onReset();
  };

  const renderColor = () => {
    if (color) {
      return color?.toUpperCase();
    }
    return "#ffffff";
  };

  return (
    <FullWrapper ref={ref}>
      <ColorWrapper>
        <InnerColorWrapper onClick={clickSelect}>
          <ColorIndicator style={{ backgroundColor: color ?? "transparent" }} />
          <ColorText onClick={clickSelect}>{color ?? "Ingen"}</ColorText>
          <Button
            onClick={clickReset}
            color="transparent"
            variant="icon"
            icon="exit"
          />
        </InnerColorWrapper>
      </ColorWrapper>
      {open && (
        <SelectorWrapper>
          {" "}
          <SketchPicker
            color={renderColor()}
            onChange={onChange}
            onChangeComplete={onChange}
          />
        </SelectorWrapper>
      )}
    </FullWrapper>
  );
};

export default ColorSelect;
