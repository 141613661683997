import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../types";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import { setTranscriptStatus } from "../redux/actions/transcripts";

const useMonitorTranscript = () => {
  const dispatch = useDispatch();
  const auth = getAuth(firebaseApp);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const monitorTranscript = useCallback(
    async (transcriptId: string) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/transcription/status?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`,
          { method: "GET" }
        );
        const response = await request.json();

        if (response.error === null) {
          dispatch(
            setTranscriptStatus({
              id: transcriptId,
              status: response?.status?.status,
              content: response.status?.content ?? null,
              progress: response.status.progress,
            })
          );
        } else {
          throw response.error;
        }
      } catch (e) {
        setIsPolling(false);
        throw e;
      }
    },
    [auth.currentUser, dispatch, selectedWorkspace.id]
  );

  return {
    monitorTranscript,
    isPolling,
    setIsPolling,
  };
};

export default useMonitorTranscript;
