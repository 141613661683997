export const SET_BLOGPOSTS_LIST = "SET_BLOGPOSTS_LIST";
export const CLEAR_BLOGPOSTS_LIST = "CLEAR_BLOGPOSTS_LIST";

export const SET_BLOGPOSTS_SEARCH_QUERY = "SET_BLOGPOSTS_SEARCH_QUERY";
export const CLEAR_BLOGPOSTS_SEARCH_QUERY = "CLEAR_BLOGPOSTS_SEARCH_QUERY";

export const SET_BLOGPOSTS_LOADING = "SET_BLOGPOSTS_LOADING";

export const APPEND_DELETION_ARRAY = "APPEND_DELETION_ARRAY";
export const SUBTRACT_DELETION_ARRAY = "SUBTRACT_DELETION_ARRAY";
export const CLEAR_DELETION_ARRAY = "CLEAR_DELETION_ARRAY";
