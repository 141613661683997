import moment from "moment";
import {
  TableData,
  TableRow as FullTableRow,
  Value,
  ButtonsWrapper,
  ButtonWrapper,
  Badge,
  ProgressWrapper,
  Status,
} from "./styled";
import Checkbox from "../../../../../../library/checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  appendDeletionArray,
  subtractDeletionArray,
} from "../../../../../../redux/actions/soundtracks";
import Button from "../../../../../../library/button";
import useModal from "../../../../../../hooks/useModal";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../../library/notification";
import useDeleteSoundtrack from "../../../../../../hooks/useDeleteSoundtrack";
import useListSoundtracks from "../../../../../../hooks/useSoundtracksList";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { IState, Soundtrack } from "../../../../../../types";
import { Link } from "react-router-dom";
import { firebaseApp } from "../../../../../../firebase";
import { getAuth } from "firebase/auth";
import useMonitorSoundtrack from "../../../../../../hooks/useMonitorSoundtrack";
import { useCallback, useEffect } from "react";

interface ITableRowProps {
  data: Soundtrack;
  arr: any;
  index: number;
}

const TableRow: React.FC<ITableRowProps> = ({ data }) => {
  const auth = getAuth(firebaseApp);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { deleteSoundtrack } = useDeleteSoundtrack();
  const { fetchSoundtracksList } = useListSoundtracks();
  const {
    openModal: openDeleteModal,
    Confirm: DeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const { monitorSoundtrack, setProgress } = useMonitorSoundtrack();

  const deletions = useSelector(
    (state: IState) => state.soundtracks.deletionArray
  );

  const fetchSoundtrackProgress = useCallback(async () => {
    const token = await auth.currentUser?.getIdToken(true);
    monitorSoundtrack(data?.id, token!);
  }, [monitorSoundtrack, auth.currentUser, data.id]);

  const handleSingleSelection = (e: any, id: string) => {
    if (e.target.checked) {
      if (!deletions.includes(id)) {
        dispatch(appendDeletionArray(id));
      }
    } else {
      if (deletions.includes(id)) {
        dispatch(subtractDeletionArray(id));
      }
    }
  };

  useEffect(() => {
    if (data.status === "progress" && data.progress !== 100) {
      setProgress(data.progress);
    }
  }, [data.status, data.progress, setProgress]);

  useEffect(() => {
    const pollSoundtrackProgress = setInterval(() => {
      if (data.status === "progress") {
        fetchSoundtrackProgress();
      } else {
        clearInterval(pollSoundtrackProgress);
      }
    }, 8500);

    return () => {
      clearInterval(pollSoundtrackProgress);
    };
  }, [data.status, data.progress, fetchSoundtrackProgress]);

  const renderStatus = (status: string) => {
    switch (status) {
      case "progress":
        return t("dashboard.pages.soundtracks.table.data.statuses.progress");
      case "completed":
        return t("dashboard.pages.soundtracks.table.data.statuses.completed");
      default:
        return t("dashboard.pages.soundtracks.table.data.statuses.failed");
    }
  };

  const clickDeleteSoundtrack = async () => {
    try {
      const deletion = await deleteSoundtrack(data.id);
      if (deletion.error === null) {
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.soundtracks.delete-single.success"
          )
        );
        closeDeleteModal();
        fetchSoundtracksList();
      }
    } catch (e) {
      sendErrorNotification(
        t("dashboard.universal.notifications.soundtracks.delete-single.failed")
      );
    }
  };

  const renderFormat = (format: "mp3" | "ogg") => {
    switch (format) {
      case "mp3":
        return t("dashboard.pages.soundtracks.table.data.formats.mp3-format");
      case "ogg":
        return t("dashboard.pages.soundtracks.table.data.formats.opus-format");
      default:
        break;
    }
  };

  return (
    <FullTableRow>
      <TableData className="select">
        <Checkbox
          onChange={(e: boolean) => handleSingleSelection(e, data.id)}
          checked={deletions.includes(data.id) ? true : false}
          name="product-select"
          ariaLabel={t("dashboard.universal.tables.select.ariaLabel") as string}
        />
      </TableData>
      <TableData>
        <Value className="bold title">
          {data.status === "progress" && (
            <ProgressWrapper>
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            </ProgressWrapper>
          )}
          {data?.title
            ? data.title
            : t("dashboard.universal.text.not-available")}
        </Value>
      </TableData>
      <TableData className="date-display">
        <Value>{moment.unix(data.timestamp).format("HH:mm DD/MM/YYYY")}</Value>
      </TableData>
      <TableData>
        <Value>
          <Status className={`${data.status}`}>
            ● {renderStatus(data.status)}
          </Status>
        </Value>
      </TableData>
      <TableData>
        <Badge>{renderFormat(data.format)}</Badge>
      </TableData>
      <TableData className="buttons">
        <ButtonsWrapper>
          <ButtonWrapper>
            <Link to={data?.downloadUrl} download>
              <Button
                disabled={data.status !== "completed"}
                variant="regular"
                color="grey"
                size="md"
                ariaLabel={
                  t(
                    "dashboard.pages.soundtracks.table.data.actions.download.ariaLabel"
                  ) as string
                }
              >
                {t(
                  "dashboard.pages.soundtracks.table.data.actions.download.title"
                )}
              </Button>
            </Link>
          </ButtonWrapper>
          <Button
            spacing="left"
            size="md"
            color="grey"
            variant="icon"
            icon="trash"
            onClick={openDeleteModal}
            ariaLabel={
              t(
                "dashboard.pages.soundtracks.table.data.actions.delete.ariaLabel"
              ) as string
            }
          />
        </ButtonsWrapper>
      </TableData>
      <DeleteModal
        title={t("dashboard.pages.soundtracks.delete-single-modal.title")}
        text={t("dashboard.pages.soundtracks.delete-single-modal.text")!}
        onSubmit={clickDeleteSoundtrack}
        submitAriaLabel={
          t(
            "dashboard.pages.soundtracks.delete-single-modal.buttons.delete.ariaLabel"
          )!
        }
        submitText={
          t(
            "dashboard.pages.soundtracks.delete-single-modal.buttons.delete.title"
          )!
        }
      />
    </FullTableRow>
  );
};

export default TableRow;
