import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import PrivacyPolicyContent from "./content";
import Meta from "./meta";

const Index = () => {
  return (
    <>
      <Meta />
      <Navigation background={true} />
      <PrivacyPolicyContent />
      <Footer />
    </>
  );
};

export default Index;
