import { useState } from "react";
import StepBar from "../../../../../library/step-bar";
import { ContentWrapper, SoundtrackForm } from "./styled";
import { useTranslation } from "react-i18next";
import SelectVoiceView from "./voice-view";
import FormatView from "./format-view";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UploadSoundtrackSchema, uploadSoundtrackSchema } from "./schema";
import UploadView from "./compose-view";
import { sendErrorNotification } from "../../../../../library/notification";
import useCreateSoundtrack from "../../../../../hooks/useCreateSoundtrack";

interface IAddModalProps {
  closeModal: () => void;
}

const AddModal: React.FC<IAddModalProps> = ({ closeModal }) => {
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const { t } = useTranslation();
  const { createSoundtrack } = useCreateSoundtrack(closeModal);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UploadSoundtrackSchema>({
    resolver: zodResolver(uploadSoundtrackSchema),
    defaultValues: {
      title: "",
      text: "",
      format: "mp3",
      voice: "alloy",
      language: "norwegian",
      public: false,
    },
  });

  const selectedVoice = watch("voice");
  const composedText = watch("text");
  const isFilePublic = watch("public");
  const selectedFormat = watch("format");
  const selectedLanguage = watch("language");

  const performCreateSoundtrack = async (data: UploadSoundtrackSchema) => {
    try {
      await createSoundtrack(data);
    } catch (e) {
      sendErrorNotification(
        t("dashboard.pages.soundtracks.notifications.upload-limit")
      );
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <FormatView
            setStep={setStep}
            setValue={setValue}
            selectedFormat={selectedFormat}
            selectedLanguage={selectedLanguage}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <SelectVoiceView
            setStep={setStep}
            setValue={setValue}
            selectedVoice={selectedVoice}
            selectedLanguage={selectedLanguage}
            register={register}
            errors={errors}
          />
        );
      default:
        return (
          <UploadView
            setValue={setValue}
            register={register}
            errors={errors}
            isFilePublic={isFilePublic}
            setStep={setStep}
            composedText={composedText}
          />
        );
    }
  };

  return (
    <SoundtrackForm onSubmit={handleSubmit(performCreateSoundtrack)}>
      <StepBar
        currentStep={step}
        titles={[
          t("dashboard.pages.soundtracks.add-modal.progress-bar.select-format"),
          t("dashboard.pages.soundtracks.add-modal.progress-bar.select-voice"),
          t(
            "dashboard.pages.soundtracks.add-modal.progress-bar.select-content"
          ),
        ]}
      />
      <ContentWrapper>{renderStep()}</ContentWrapper>
    </SoundtrackForm>
  );
};

export default AddModal;
