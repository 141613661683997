import { useSelector } from "react-redux";
import {
  TableContainer,
  Table as FullTable,
  TableRow,
  TableHeader,
  Title,
} from "./styled";
import SingleMember from "./list-item";
import EmptyView from "../../../../../../components/empty-view";
import { useTranslation } from "react-i18next";
import { IState } from "../../../../../../types";

const ListView = () => {
  const { t } = useTranslation();
  const members = useSelector((state: IState) => state.workspace.members);

  const TITLES = [
    t("dashboard.pages.workspace.bottom-view.table.titles.name"),
    t("dashboard.pages.workspace.bottom-view.table.titles.email"),
    t("dashboard.pages.workspace.bottom-view.table.titles.role"),
    t("dashboard.pages.workspace.bottom-view.table.titles.status"),
    t("dashboard.pages.workspace.bottom-view.table.titles.date"),
    t("dashboard.pages.workspace.bottom-view.table.titles.action"),
  ];

  if (members?.length === 0) {
    return (
      <EmptyView
        title={t("dashboard.pages.workspace.bottom-view.empty-view.title")}
        description={t("dashboard.pages.workspace.bottom-view.empty-view.text")}
      />
    );
  }

  return (
    <TableContainer>
      <FullTable>
        <TableRow className="header">
          {TITLES.map((title) => (
            <TableHeader>
              <Title>{title}</Title>
            </TableHeader>
          ))}
        </TableRow>
        {members?.map((data) => (
          <SingleMember data={data} />
        ))}
      </FullTable>
    </TableContainer>
  );
};

export default ListView;
