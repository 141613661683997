import { useNavigate } from "react-router-dom";
import Button from "../../../../library/button";
import { Title } from "../../../../library/title";
import {
  ButtonCol,
  ContentContainer,
  Form,
  InnerPageContainer,
  PageContainer,
  TitleCol,
  UpperRow,
} from "./styled";
import { useRef, useState } from "react";
import defaultThumbnail from "../../../../assets/img/blog-thumbnail.svg";
import useCreateBlogpost from "../../../../hooks/useCreateBlogpost";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../library/notification";
import { useForm } from "react-hook-form";
import { AddBlogpostSchema, schema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { FIELDS } from "./fields";

const Content = () => {
  const navigate = useNavigate();
  const { createBlogpost } = useCreateBlogpost();
  const fileInput = useRef<HTMLInputElement>(null);
  const prefixRef = useRef<HTMLInputElement>(null);

  const [isEditingPrefix, setIsEditingPrefix] = useState<boolean>(false);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
    watch,
  } = useForm<AddBlogpostSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: "",
      prefix: "",
      excerpt: "",
      status: "draft",
      content: "",
      thumbnail: "",
    },
  });

  const blogpostThumbnail = watch("thumbnail");
  const blogpostContent = watch("content");
  const blogpostStatus = watch("status");
  const blogpostPrefix = watch("prefix");

  const clickEditPrefix = () => {
    setIsEditingPrefix(!isEditingPrefix);
    if (prefixRef?.current) {
      prefixRef.current.focus();
    }
  };

  const clickUpload = () => {
    fileInput?.current!.click();
  };

  const handleFileDrop = (file: any, type: string) => {
    if (type === "click") {
      setValue("thumbnail", file.target.files[0]);
    } else {
      setValue("thumbnail", file[0]);
    }
  };

  const handleCreateBlogpost = async (data: AddBlogpostSchema) => {
    try {
      const create = await createBlogpost(data);
      if (create?.error === null) {
        sendSuccessNotification(
          `Innlegget ble ${
            blogpostStatus === "published" ? "publisert" : "lagret"
          }.`
        );
        if (blogpostStatus === "published") {
          navigate("/dashboard/admin/blogposts");
        }
      } else {
        throw create?.error;
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke lagre innlegg.");
    }
  };

  const renderThumbnail = () => {
    if (typeof blogpostThumbnail === "object") {
      return URL.createObjectURL(blogpostThumbnail as any);
    }
    if (blogpostThumbnail !== "") {
      return blogpostThumbnail;
    }
    return defaultThumbnail;
  };

  const renderUploadButton = () => {
    if (blogpostThumbnail !== "") {
      return "Endre bilde";
    }
    return "Legg til bilde";
  };

  const createPrefix = (e: any) => {
    const title = e.target.value;
    setValue("title", title);
    if (blogpostPrefix === "") {
      let trimmed = title
        ?.replaceAll(" ", "-")
        .replaceAll("æ", "ae")
        .replaceAll("ø", "o")
        .replaceAll("å", "a")
        .toLowerCase();
      setValue("prefix", trimmed);
    }
  };

  const onChangePrefix = (e: any) => {
    let value = e.target.value;
    let trimmed = value
      ?.replaceAll(" ", "-")
      .replaceAll("æ", "ae")
      .replaceAll("ø", "o")
      .replaceAll("å", "a")
      .toLowerCase();
    setValue("prefix", trimmed);
  };

  return (
    <PageContainer>
      <InnerPageContainer>
        <Form onSubmit={handleSubmit(handleCreateBlogpost)}>
          <UpperRow>
            <TitleCol>
              <Title>Nytt blogginnlegg</Title>
            </TitleCol>
            <ButtonCol>
              <Button type="submit" variant="regular" color="blue" icon="save">
                {blogpostStatus === "published"
                  ? "Publiser innlegg"
                  : "Lagre innlegg"}
              </Button>
            </ButtonCol>
          </UpperRow>
          <ContentContainer>
            {FIELDS.map((field) =>
              field.renderField(errors, register, setValue, {
                blogpostThumbnail,
                handleFileDrop,
                fileInput,
                clickUpload,
                renderUploadButton,
                renderThumbnail,
                blogpostContent,
                blogpostStatus,
                createPrefix,
                prefixRef,
                isEditingPrefix,
                blogpostPrefix,
                onChangePrefix,
                clickEditPrefix,
              })
            )}
          </ContentContainer>
        </Form>
      </InnerPageContainer>
    </PageContainer>
  );
};

export default Content;
