import { UploadTranscriptSchema } from "../schema";
import { UseFormSetValue } from "react-hook-form";
import { Dispatch, SetStateAction } from "react";
import Selections from "../../../../../../library/selections";
import { ButtonsWrapper, SelectionWrapper } from "./styled";
import Button from "../../../../../../library/button";
import { useTranslation } from "react-i18next";
import { OPTIONS } from "./options";

interface FormatViewProps {
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  selectedFormat: string;
  setSelectionType: (type: "single" | "double") => void;
  setStep: Dispatch<SetStateAction<1 | 2 | 3 | 4>>;
}

const FormatView = ({
  setValue,
  selectedFormat,
  setStep,
  setSelectionType,
}: FormatViewProps) => {
  const { t } = useTranslation();

  const handleSelection = (type: any) => {
    setSelectionType(type?.value);
  };

  const clickBack = () => {
    setStep(2);
  };

  const clickNext = () => {
    setStep(4);
  };

  return (
    <SelectionWrapper>
      <Selections
        defaultSelected={selectedFormat}
        options={OPTIONS}
        onSelect={handleSelection}
        type="default"
      />
      <ButtonsWrapper>
        <Button
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.format-step.buttons.back.ariaLabel"
            ) as string
          }
          onClick={clickBack}
          variant="regular"
          color="grey"
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.format-step.buttons.back.title"
          )}
        </Button>
        <Button
          type="submit"
          variant="regular"
          color="blue"
          onClick={clickNext}
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.format-step.buttons.next.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.format-step.buttons.next.title"
          )}
        </Button>
      </ButtonsWrapper>
    </SelectionWrapper>
  );
};

export default FormatView;
