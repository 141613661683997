export const SET_WORKSPACE_DETAILS = "SET_WORKSPACE_DETAILS";
export const SET_LOADING_WORKSPACE_DETAILS = "SET_LOADING_WORKSPACE_DETAILS";

export const SET_MEMBERS_LIST = "SET_MEMBERS_LIST";
export const SET_LOADING_MEMBERS_LIST = "SET_LOADING_MEMBERS_LIST";

export const SET_PAYMENT_METHODS_LIST = "SET_PAYMENT_METHODS_LIST";
export const SET_LOADING_PAYMENT_METHODS_LIST =
  "SET_LOADING_PAYMENT_METHODS_LIST";

export const SET_PAYMENT_HISTORY_LIST = "SET_PAYMENT_HISTORY_LIST";
export const SET_LOADING_PAYMENT_HISTORY_LIST =
  "SET_LOADING_PAYMENT_HISTORY_LIST";
