import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../library/button";
import { Title } from "../../../../library/title";
import {
  BlogpostForm,
  ButtonCol,
  ContentContainer,
  InnerPageContainer,
  PageContainer,
  TitleCol,
  UpperRow,
} from "./styled";
import { useEffect, useRef, useState } from "react";
import defaultThumbnail from "../../../../assets/img/blog-thumbnail.svg";
import useFetchAdminBlogpost from "../../../../hooks/useFetchAdminBlogpost";
import { useSelector } from "react-redux";
import { IState } from "../../../../types";
import TableLoader from "../../../../library/table-loader";
import useUpdateBlogpost from "../../../../hooks/useUpdateBlogpost";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../library/notification";
import useDeleteBlogpost from "../../../../hooks/useDeleteBlogpost";
import useModal from "../../../../hooks/useModal";
import { EditBlogpostSchema, schema } from "./schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FIELDS } from "./fields";

const Content = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInput = useRef<HTMLInputElement>(null);
  const { deleteBlogpost } = useDeleteBlogpost();
  const { updateBlogpost } = useUpdateBlogpost();
  const { getAdminBlogpost, loading } = useFetchAdminBlogpost();
  const { openModal: openDeleteModal, Confirm: DeleteModal } = useModal();
  const details = useSelector((state: IState) => state.blogpost.data);
  const user = useSelector((state: IState) => state.user.details);
  const prefixRef = useRef<HTMLInputElement>(null);

  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<EditBlogpostSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      id: "",
      thumbnail: null,
      title: "",
      status: "published",
      excerpt: "",
      content: "",
    },
  });

  const blogpostId = watch("id");
  const blogpostThumbnail = watch("thumbnail");
  const blogpostContent = watch("content");
  const blogpostStatus = watch("status");
  const blogpostPrefix = watch("prefix");

  const [isEditingPrefix, setIsEditingPrefix] = useState<boolean>(false);

  useEffect(() => {
    if (user?.uid && id) {
      getAdminBlogpost(id);
    }
  }, [getAdminBlogpost, id, user?.uid, details?.id]);

  useEffect(() => {
    if (details?.id && blogpostId === "") {
      setValue("id", details?.id);
      setValue("thumbnail", details?.thumbnail);
      setValue("status", details?.status);
      setValue("title", details?.title);
      setValue("prefix", details?.prefix);
      setValue("excerpt", details?.excerpt);
      setValue("content", details?.content);
    }
  }, [details, setValue, blogpostId]);

  const clickEditPrefix = () => {
    setIsEditingPrefix(!isEditingPrefix);
    if (prefixRef?.current) {
      prefixRef.current.focus();
      prefixRef.current.scrollIntoView();
    }
  };

  const performUpdate = async (data: EditBlogpostSchema) => {
    try {
      const update = await updateBlogpost(data);
      if (update?.error === null) {
        sendSuccessNotification("Innlegget ble oppdatert.");
      } else {
        throw update?.error;
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke oppdatere innlegg.");
    }
  };

  const clickDeleteBlogpost = async () => {
    try {
      const deletion = await deleteBlogpost(details.id);
      if (deletion.error === null) {
        sendSuccessNotification("Innlegget ble slettet.");
        navigate("/dashboard/admin/blogposts");
      } else {
        throw deletion.error;
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke slette innlegg.");
    }
  };

  const clickUpload = () => {
    fileInput?.current!.click();
  };

  const handleFileDrop = (file: any, type: string) => {
    if (type === "click") {
      setValue("thumbnail", file.target.files[0]);
    } else {
      setValue("thumbnail", file[0]);
    }
  };

  const renderThumbnail = () => {
    if (typeof blogpostThumbnail === "object") {
      return URL.createObjectURL(blogpostThumbnail as any);
    }
    if (blogpostThumbnail !== "") {
      return blogpostThumbnail;
    }
    return defaultThumbnail;
  };

  const renderUploadButton = () => {
    if (blogpostThumbnail !== "") {
      return "Endre bilde";
    }
    return "Legg til bilde";
  };

  const onChangePrefix = (e: any) => {
    let value = e.target.value;
    let trimmed = value
      ?.replaceAll(" ", "-")
      .replaceAll("æ", "ae")
      .replaceAll("ø", "o")
      .replaceAll("å", "a")
      .toLowerCase();
    setValue("prefix", trimmed);
  };

  const renderView = () => {
    if (loading) {
      return <TableLoader message="Henter blogginnlegg.." />;
    }
    return (
      <ContentContainer>
        {FIELDS.map((field) =>
          field?.renderFields(errors, register, setValue, {
            blogpostThumbnail,
            blogpostContent,
            blogpostStatus,
            handleFileDrop,
            fileInput,
            clickUpload,
            renderThumbnail,
            onChangePrefix,
            clickEditPrefix,
            isEditingPrefix,
            blogpostPrefix,
            prefixRef,
            renderUploadButton,
          })
        )}
      </ContentContainer>
    );
  };

  return (
    <PageContainer>
      <InnerPageContainer>
        <BlogpostForm onSubmit={handleSubmit(performUpdate)}>
          <UpperRow>
            <TitleCol>
              <Title>Endre blogginnlegg</Title>
            </TitleCol>
            <ButtonCol>
              <Button
                variant="regular"
                color="blue"
                spacing="right"
                icon="refresh"
                type="submit"
              >
                Oppdater
              </Button>
              <Button
                onClick={openDeleteModal}
                variant="regular"
                color="grey"
                icon="trash"
                type="button"
              >
                Slett innlegg
              </Button>
            </ButtonCol>
          </UpperRow>
          {renderView()}
          <DeleteModal
            title="Slette innlegg?"
            text="Dersom du sletter dette innlegget vil du ikke ha mulighet til å gjenopprette det."
            submitText="Ja, slett det"
            onSubmit={clickDeleteBlogpost}
          />
        </BlogpostForm>
      </InnerPageContainer>
    </PageContainer>
  );
};

export default Content;
