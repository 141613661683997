import DashboardLayout from "../../../components/dashboard-layout";
import Content from "./content";

const Overview = () => {
  return (
    <DashboardLayout>
      <Content />
    </DashboardLayout>
  );
};

export default Overview;
