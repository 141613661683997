import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDetails, setLoadingDetails } from "../redux/actions/workspace";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { IState } from "../types";

const useWorkspaceDetails = () => {
  const dispatch = useDispatch();
  const auth = getAuth(firebaseApp);
  const loading = useSelector(
    (state: IState) => state.workspace.loadingDetails
  );
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const fetchWorkspaceDetails = useCallback(async () => {
    try {
      dispatch(setLoadingDetails(true));
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/details?workspaceId=${selectedWorkspace.id}&token=${token}`,
        {
          method: "GET",
        }
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(setLoadingDetails(false));
        dispatch(setDetails(response.result));
      } else {
        throw response.error;
      }
    } catch (e) {
      dispatch(setLoadingDetails(false));
      throw e;
    }
  }, [dispatch, auth.currentUser, selectedWorkspace.id]);
  return { fetchWorkspaceDetails, loading };
};

export default useWorkspaceDetails;
