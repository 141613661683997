import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const RegistrationForm = styled.form`
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
`;

export const Field = styled.div`
  margin-bottom: 0.625rem;
  input {
    margin-top: 0.3125rem;
  }
  @media (max-width: 767px) {
    input {
      margin-top: 0rem;
    }

    &:last-of-type {
      padding-top: 0rem;
    }
  }
`;

export const InputRow = styled(Row)`
  row-gap: 0.9375rem;
`;

export const InputCol = styled(Col)`
  a {
    font-family: Poppins, sans-serif;
    font-size: 14px;
  }
`;

export const SingleCheckWrapper = styled.div`
  display: flex;
  & > span {
    font-size: 0.875rem;
    margin-top: -0.25rem;
    margin-left: 0.625rem;
    a {
      color: #2f8de4;
    }
  }
  &:last-of-type {
    margin-top: 0.625rem;
  }
`;

export const InnerSingleWrapper = styled.div`
  display: block;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  span {
    margin-top: -0.3125rem;
    margin-left: 0.625rem;
  }
`;
