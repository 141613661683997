import Tabs from "../../../../../library/tabs";
import { Title } from "../../../../../library/title";
import { ContentContainer, TabsWrapper } from "./styled";
import UserDetails from "./user-details";
import ChangePassword from "./change-password";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const SELECTIONS = [
    {
      id: 0,
      key: "private",
      title: t(
        "dashboard.pages.account.settings-view.tabs.user-details.tab-title"
      ),
      display: <UserDetails />,
    },
    {
      id: 1,
      key: "shared",
      title: t(
        "dashboard.pages.account.settings-view.tabs.change-password.tab-title"
      ),
      display: <ChangePassword />,
    },
  ];

  return (
    <ContentContainer>
      <Title>{t("dashboard.pages.account.settings-view.title")}</Title>
      <TabsWrapper>
        <Tabs selections={SELECTIONS} />
      </TabsWrapper>
    </ContentContainer>
  );
};

export default Index;
