import { useState } from "react";
import { getIcon } from "../../../../../../utils/get-icon";
import { ControllerWrapper, IconWrapper } from "./styled";

interface VideoPlayerMobileProps {
  togglePlayPause: () => void;
  isPlaying: boolean;
}

const VideoPlayerMobile: React.FC<VideoPlayerMobileProps> = ({
  togglePlayPause,
  isPlaying,
}) => {
  const [showIcon, setShowIcon] = useState(false);

  const handleToggle = () => {
    togglePlayPause();
    setShowIcon(true);

    setTimeout(() => {
      setShowIcon(false);
    }, 1000);
  };

  return (
    <ControllerWrapper onClick={handleToggle}>
      <IconWrapper visible={showIcon}>
        {isPlaying ? getIcon("play") : getIcon("pause")}
      </IconWrapper>
    </ControllerWrapper>
  );
};

export default VideoPlayerMobile;
