import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import {
  Article,
  Container,
  Paragraph,
  Section,
  VisualizationCol,
  VisualizationDescription,
  VisualizationDetail,
  VisualizationRow,
  VisualizationTitle,
  VisualizationWrapper,
} from "./styled";

const CookiesContent = () => {
  const COOKIE_TYPES = [
    {
      id: 0,
      title: "Necessary cookies",
      description:
        "Disse hjelper oss med å sørge for at du kan navigere deg på best mulig måte gjennom siden vår.",
      detail:
        "Autentisering - vi sjekker om du er innlogget og tar deg til dashboardet automatisk.",
    },
    {
      id: 1,
      title: "Performance cookies",
      description:
        "Dette bruker vi for å forstå hvordan brukere benytter seg av siden vår og hvordan vi kan forbedre oss.",
      detail:
        "Vi bruker blant annet Google Analytics til å samle inn informasjon om våre besøkende.",
    },
    {
      id: 2,
      title: "Functional cookies",
      description:
        "Hjelper oss med å presentere data og informasjon basert på data vi har om deg.",
      detail:
        "En administrator vil for eksempel se mye mer informasjon og ha flere rettigheter enn en vanlig bruker.",
    },
    {
      id: 3,
      title: "Targeting cookies",
      description:
        "Sørger for at vi kan servere deg relevant innhold for din virksomhet basert på ulike data.",
      detail:
        "Annonsering på Google og Facebook er noe vi bruker for å nå ut til folk som kan være interessert i tjenesten.",
    },
  ];

  return (
    <Section>
      <Container>
        <Article>
          <Paragraph>
            <Title>Cookies</Title>
            <Text color="dark">
              Vi bruker cookies som et verktøy for å gi deg en optimal
              brukeropplevelse mens du er på siden. Dette hjelper oss med å
              forbedre, vedlikeholde og kontrollere tjenesten, og dette vil
              aldri bli brukt til reklame og markedsføring hos noen tredjepart.
              For å gi deg en bedre oversikt over hva vi bruker cookies på, har
              vi laget en oversikt nedenfor.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Hva er cookies?</Title>
            <Text color="dark">
              Cookies er enkelt og greit små biter med tekst som lagres på din
              datamaskin, mobiltelefon eller nettbrett av hver enkelt side du
              besøker. Hver cookie er helt unik, og inneholder anonym
              informasjon som for eksempel en variabel, ulike nummere eller noe
              annet nettsiden velger.
              <br />
              <br />
              Nesten hver eneste side bruker cookies for å forbedre
              brukeropplevelsen og servere mer spesifikt og konkret innhold.
              Cookies skiller på å være "persistent" - altså lagret frem til du
              selv sletter nettleserhistorikken, eller "session-basert" som vil
              si at de slettes når du lukker fanen eller gjør en handling som å
              logge ut fra nettstedet.
              <br />
              <br />
              Randi setter og kontrollerer cookies som er lagret med
              randi.ai-domenet, utenom cookies hos en tredjepartstjeneste som
              for eksempel analyseverktøyet vi benytter oss av.
            </Text>
          </Paragraph>
          <Paragraph>
            <VisualizationWrapper>
              <VisualizationRow>
                {COOKIE_TYPES.map((type) => (
                  <VisualizationCol md={6}>
                    <VisualizationTitle>{type.title}</VisualizationTitle>
                    <VisualizationDescription>
                      {type.description}
                    </VisualizationDescription>
                    <VisualizationDetail>{type.detail}</VisualizationDetail>
                  </VisualizationCol>
                ))}
              </VisualizationRow>
            </VisualizationWrapper>
          </Paragraph>
          <Paragraph>
            <Title>Kan du slette cookies?</Title>
            <Text color="dark">
              Det er veldig enkelt. Du kan forhindre at nettleseren lagrer
              cookies, og/eller slette dem i innstillinger på nettleseren din.
              Besøk{" "}
              <a href="https://www.internetcookies.com">
                www.internetcookies.com
              </a>{" "}
              for mer informasjon på cookies og hvordan slette dem i ulike
              nettlesere. Hvis du har noen spørsmål om vår bruk av cookies kan
              du <a href="mailto:andreas@randi.ai">sende oss en e-post</a>.
            </Text>
          </Paragraph>
        </Article>
      </Container>
    </Section>
  );
};

export default CookiesContent;
