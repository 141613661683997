import { useSelector } from "react-redux";
import Button from "../../../../../../library/button";
import InputFields from "./input-fields";
import { ButtonsWrapper } from "./styled";
import { IState } from "../../../../../../types";
import { useTranslation } from "react-i18next";
import TableLoader from "../../../../../../library/table-loader";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { UploadSoundtrackSchema } from "../schema";
import { Dispatch, SetStateAction } from "react";

interface FormatViewProps {
  setValue: UseFormSetValue<UploadSoundtrackSchema>;
  register: UseFormRegister<UploadSoundtrackSchema>;
  errors: FieldErrors;
  isFilePublic: boolean;
  setStep: Dispatch<SetStateAction<1 | 2 | 3>>;
  composedText: string;
}

const FormatView = ({
  setValue,
  register,
  errors,
  isFilePublic,
  setStep,
  composedText,
}: FormatViewProps) => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: IState) => state.soundtracks.loadingCreation
  );

  const clickBack = () => {
    setStep(2);
  };

  return (
    <div>
      {loading ? (
        <TableLoader
          message={
            t(
              "dashboard.pages.soundtracks.add-modal.content.upload-view.message"
            ) as string
          }
        />
      ) : (
        <>
          <InputFields
            register={register}
            setValue={setValue}
            errors={errors}
            isFilePublic={isFilePublic}
            composedText={composedText}
          />
          <ButtonsWrapper>
            <Button
              onClick={clickBack}
              variant="regular"
              color="grey"
              ariaLabel={
                t(
                  "dashboard.pages.soundtracks.add-modal.content.compose-step.buttons.back.ariaLabel"
                ) as string
              }
            >
              {t(
                "dashboard.pages.soundtracks.add-modal.content.compose-step.buttons.back.title"
              )}
            </Button>
            <Button
              type="submit"
              variant="regular"
              color="blue"
              disabled={loading}
              ariaLabel={
                t(
                  "dashboard.pages.soundtracks.add-modal.content.compose-step.buttons.create.ariaLabel"
                ) as string
              }
            >
              {t(
                "dashboard.pages.soundtracks.add-modal.content.compose-step.buttons.create.title"
              )}
            </Button>
          </ButtonsWrapper>
        </>
      )}
    </div>
  );
};

export default FormatView;
