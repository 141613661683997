import styled from "@emotion/styled";

export const ControllerWrapper = styled.div`
  margin-top: 15px;
  @media (max-width: 975px) {
    padding: 0px 0px;
  }
`;

export const BottomControllerWrapper = styled.div`
  display: flex;
  background-color: rgb(244, 244, 244);
  border-radius: 1rem;
  padding: 0px 25px;
`;

export const LeftBottomWrapper = styled.div`
  flex: 50%;
  display: flex;
  align-items: center;
`;

export const MainControls = styled.div`
  display: flex;
  align-items: center;
`;

export const SpeedButton = styled.button`
  border: none;
  background: none;
  font-size: 24px;
  color: #2f8de4;
  padding: 0px;
`;

export const CenterBottomWrapper = styled.div``;

export const AudioButton = styled.button`
  border: none;
  background: none;
  font-size: 20px;
  margin-left: 15px;
  color: #2f8de4;
`;

export const VolumeInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  outline: none;
  background: none;
  cursor: pointer;
  max-width: 100px;

  &::-webkit-slider-runnable-track {
    width: 10%;
    height: 8px;
    background: linear-gradient(
      to right,
      #2f8de4 0%,
      #2f8de4 var(--player-volume, 0%),
      #eaeaea var(--player-volume, 0%),
      #eaeaea 100%
    );
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 13px;
    height: 13px;
    margin-top: -3px;
    background: #2f8de4;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-track {
    width: 100%;
    height: 3px;
    background: #ddd;
    border-radius: 5px;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export const ExpandButton = styled.button`
  color: #2f8de4;
  font-size: 20px;
  border-radius: 8px;
  border: none;
  margin-left: 10px;
`;

export const RightBottomWrapper = styled.div`
  flex: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SoundControls = styled.div`
  display: flex;
  align-items: center;
`;

export const DurationWrapper = styled.div``;

export const Duration = styled.span`
  font-family: Poppins, sans-serif;
  color: grey;
`;

export const PlayButton = styled.button`
  height: 70px;
  width: 70px;
  font-size: 38px;
  border: none;
  background: none;
  color: #2f8de4;
`;

export const BeginningButton = styled.button`
  border: none;
  background: none;
  font-size: 22px;
  color: #2f8de4;
  margin-left: 15px;
`;
