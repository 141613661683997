export const REQUEST_CREATE_TRANSCRIPT = "REQUEST_CREATE_TRANSCRIPT";
export const GRANT_CREATE_TRANSCRIPT = "GRANT_CREATE_TRANSCRIPT";
export const REVOKE_CREATE_TRANSCRIPT = "REVOKE_CREATE_TRANSCRIPT";
export const CLEAR_CREATE_TRANSCRIPT = "CLEAR_CREATE_TRANSCRIPT";

export const SET_TRANSCRIPTS_LIST = "SET_TRANSCRIPTS_LIST";
export const CLEAR_TRANSCRIPTS_LIST = "CLEAR_TRANSCRIPTS_LIST";

export const SET_TRANSCRIPTS_LIST_LOADING = "SET_TRANSCRIPTS_LIST_LOADING";
export const SET_TRANSCRIPT_UPLOADING = "SET_TRANSCRIPT_UPLOADING";

export const SET_TRANSCRIPTS_SEARCH_QUERY = "SET_TRANSCRIPTS_SEARCH_QUERY";
export const CLEAR_TRANSCRIPTS_SEARCH_QUERY = "CLEAR_TRANSCRIPTS_SEARCH_QUERY";

export const APPEND_DELETION_ARRAY = "APPEND_DELETION_ARRAY";
export const SUBTRACT_DELETION_ARRAY = "SUBTRACT_DELETION_ARRAY";
export const CLEAR_DELETION_ARRAY = "CLEAR_DELETION_ARRAY";

export const SET_TRANSCRIPT_STATUS = "SET_TRANSCRIPT_STATUS";
