import { useState, useEffect } from "react";
import { Label } from "../../../../../../../library/label";
import { ActionButton, RecordWrapper } from "./styled";
import { getIcon } from "../../../../../../../utils/get-icon";
import { useReactMediaRecorder } from "react-media-recorder-2";
import { sendErrorNotification } from "../../../../../../../library/notification";
import { useStopwatch } from "react-timer-hook";
import short from "short-uuid";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../../library/button";
import { useSelector } from "react-redux";
import { IState } from "../../../../../../../types";
import UploadingView from "../upload-view/uploading-view";
import { UseFormSetValue } from "react-hook-form";
import { UploadTranscriptSchema } from "../../schema";

interface IRecordViewProps {
  selectedFile: any;
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  progress: number;
}

const RecordView: React.FC<IRecordViewProps> = ({
  selectedFile,
  setValue,
  progress,
}) => {
  const { t } = useTranslation();
  const { startRecording, stopRecording, status, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({
      audio: true,
      mediaRecorderOptions: { mimeType: "audio/wav" },
    });
  const {
    seconds,
    minutes,
    hours,
    start: startTimer,
    reset: resetTimer,
  } = useStopwatch({ autoStart: false });
  let [recording, setRecording] = useState<boolean>(false);
  let [fileUrl, setFileUrl] = useState<any>(null);
  const uploading = useSelector(
    (state: IState) => state.transcripts.loadingUpload
  );

  useEffect(() => {
    if (status === "stopped" && mediaBlobUrl) {
      setFileUrl(mediaBlobUrl);

      const convertFile = async () => {
        try {
          const audioBlob = await fetch(mediaBlobUrl as any).then((r) =>
            r.blob()
          );
          const audiofile = new File(
            [audioBlob],
            `audio_recording_${short.generate()}.wav`,
            {
              type: "audio/wav",
            }
          );
          return audiofile;
        } catch (e) {
          throw e;
        }
      };

      convertFile()
        .then((result) => {
          if (!selectedFile?.name) {
            setValue("file", result);
          }
        })
        .catch((e) => {
          sendErrorNotification(
            t("dashboard.pages.transcripts.notifications.failed-recording")
          );
        });
    } else {
      // Add logic to reset state when not recording or mediaBlobUrl is falsy
      setFileUrl(null);
    }
  }, [status, mediaBlobUrl, selectedFile, setValue, t]);

  const handleRecord = () => {
    if (recording) {
      resetTimer();
      stopRecording();
      setRecording(false);
    } else {
      startTimer();
      startRecording();
      setRecording(true);
    }
  };

  const clickCancel = () => {
    setFileUrl(null);
    setValue("file", { name: null, size: 0 } as any);
    setRecording(false);
    resetTimer();
    clearBlobUrl();
  };

  return (
    <RecordWrapper>
      {uploading ? (
        <UploadingView progress={progress} />
      ) : (
        <>
          {selectedFile?.name ? (
            <>
              <audio controls>
                <source src={fileUrl} type="audio/mpeg" />
              </audio>
              <Button
                icon="refresh"
                className="replay"
                variant="regular"
                size="sm"
                color="grey"
                onClick={clickCancel}
              >
                Spill inn på nytt
              </Button>
            </>
          ) : (
            <>
              <ActionButton
                className={`${recording ? "recording" : ""}`}
                onClick={handleRecord}
                type="button"
                aria-label={
                  recording
                    ? (t(
                        "dashboard.pages.transcripts.add-modal.content.source-step.audio-container.stop-recording-button.ariaLabel"
                      ) as string)
                    : (t(
                        "dashboard.pages.transcripts.add-modal.content.source-step.audio-container.record-button.ariaLabel"
                      ) as string)
                }
              >
                {recording ? getIcon("stop") : getIcon("record")}
              </ActionButton>
              <Label>
                {recording
                  ? `${hours < 10 && hours !== null ? "0" : ""}${hours}:${
                      minutes < 10 && minutes !== null ? "0" : ""
                    }${minutes}:${
                      seconds < 10 && seconds !== null ? "0" : ""
                    }${seconds}`
                  : t(
                      "dashboard.pages.transcripts.add-modal.content.source-step.audio-container.text"
                    )}
              </Label>
            </>
          )}
        </>
      )}
    </RecordWrapper>
  );
};

export default RecordView;
