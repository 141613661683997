import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const SelectionWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Field = styled.div`
  margin-bottom: 15px;
  input {
    margin-top: 5px;
  }
`;

export const DetailsRow = styled(Row)`
  margin-bottom: 15px;
`;

export const DetailCol = styled(Col)``;

export const Thumbnail = styled.img`
  width: 100%;
`;

export const SmallText = styled.small`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 12px;
  padding-left: 20px;
  display: block;
  margin-top: 10px;
`;
