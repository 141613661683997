import styled from "@emotion/styled";

export const Wrapper = styled.div``;

export const BackButton = styled.button`
  border: none;
  background: none;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: grey;
`;
