import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 6.25rem 1.25rem;
  @media (max-width: 767px) {
    padding-top: 3.125rem;
  }
`;

export const PageRow = styled(Row)``;

export const PageCol = styled(Col)`
  @media (max-width: 767px) {
    &:first-of-type {
      padding-bottom: 50px;
    }
  }
`;

export const ContentWrapper = styled.div`
  max-width: 680px;
`;

export const RegisterWrapper = styled.div``;

export const DetailsRow = styled(Row)`
  margin: 25px 0px;
  padding: 10px 0px;
  border-bottom: 0.125rem dashed #e1e1e1;
  border-top: 0.125rem dashed #e1e1e1;
`;

export const DetailsCol = styled(Col)`
  padding-left: 0px;
`;

export const DetailsTitle = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #2f8de4;
`;
