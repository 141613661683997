import { useCallback, useState } from "react";
import ModalComponent from "../library/modal/index";
import ConfirmComponent from "../library/confirm";

interface IModalProps {
  title: string;
  text?: string;
  onSubmit?: any;
  submitText?: string;
  submitAriaLabel?: string;
  children?: JSX.Element;
}

const useModal = (initialState = false) => {
  const [open, setOpen] = useState<boolean>(initialState);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const Modal: React.FC<IModalProps> = ({ title, children }) => {
    if (!open) return null;
    return (
      <ModalComponent title={title} onClose={closeModal} open={open}>
        {children}
      </ModalComponent>
    );
  };

  const Confirm: React.FC<IModalProps> = ({
    title,
    text,
    onSubmit,
    submitText,
    submitAriaLabel,
  }) => {
    if (!open) return null;
    return (
      <ConfirmComponent
        title={title}
        text={text}
        open={open}
        onClose={closeModal}
        onSubmit={onSubmit}
        submitText={submitText}
        submitAriaLabel={submitAriaLabel}
      />
    );
  };

  return { open, openModal, closeModal, Modal, Confirm };
};

export default useModal;
