import * as type from "../types/blog";

export const setBlogposts = (payload: any) => {
  return {
    type: type.SET_BLOGPOSTS_LIST,
    blogposts: payload,
  };
};

export const clearBlogposts = () => {
  return {
    type: type.CLEAR_BLOGPOSTS_LIST,
  };
};

export const setBlogpostsLoading = (payload: any) => {
  return {
    type: type.SET_BLOGPOSTS_LOADING,
    loading: payload,
  };
};
