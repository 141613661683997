import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section``;

export const Container = styled.div`
  max-width: 65.625rem;
  margin: 0 auto;
  padding: 9.375rem 1.25rem;
  @media (max-width: 767px) {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
`;

export const Article = styled.article``;

export const Paragraph = styled.div`
  margin: 3.125rem 0rem;
`;

export const Bold = styled.span`
  font-weight: 500;
  color: #3b3b3b;
`;

export const VisualizationWrapper = styled.div``;

export const VisualizationRow = styled(Row)``;

export const VisualizationCol = styled(Col)``;

export const VisualizationTitle = styled.h3`
  color: #2f8de4;
  font-family: "Poppins", sans-serif;
  font-size: 1.375rem;
`;

export const VisualizationDescription = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: grey;
  display: block;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  line-height: 1.7rem;
`;

export const VisualizationDetail = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  display: block;
  line-height: 1.7rem;
  color: #3b3b3b;
`;
